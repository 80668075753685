<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props:['department'],
  data() {
    return {
      date2: moment().startOf('month').format("DD MMM, YYYY")  +  " to " + moment().endOf('month').format("DD MMM, YYYY"),
      rangeDateconfig: {
        mode: "range",
        dateFormat: "d M, Y",
        defaultDate: this.date2
      },
      sourcesData: [],
      totalQty: 0
    }
  },
  components: {
    flatPickr,
  },
  methods:{
    async getSourcesReport() {

      const data = {
        token: localStorage.getItem('jwt'),
        dateRange: this.date2,
        department: this.department
      }

      await axios
          .post('/api/management/reportSources', data)
          .then(response => {
            this.totalQty = 0;
            this.sourcesData = response.data
            this.sourcesData.forEach((row) => this.totalQty += row.Qty)
          })
          .catch(error => {
            if (error.response) {

              if(error.response.data.detail === 'Your session is expired!'){
                this.$router.push('logout')
              }

              Swal.fire("Oops!", "Something was wrong, please contact your SysAdmin.", "error")
            }
          })

    },
    numberWithCommas(x) {
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
      return x;
    }
  },
  async mounted(){
    await this.getSourcesReport();
  }
}
</script>

<template>
  <div class="card card-height-100">
    <div class="card-header align-items-center d-flex">
      <h4 class="card-title flex-grow-1">Agents Added By Sources</h4>
      <div class="flex-shrink-1">
        <flat-pickr
            v-model="date2"
            placeholder="Select date range"
            :config="rangeDateconfig"
            class="form-control bg-light border-light"
            @change="getSourcesReport"
        ></flat-pickr>
      </div>
    </div>

    <div class="card-body">

      <div class="row align-items-center">
        <div class="col-6">
          <h6 class="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">Total Agents Added</h6>
          <h4 class="fs- mb-0">{{ numberWithCommas(this.totalQty) }}</h4>
          <!--          <p class="mb-0 mt-2 text-muted">-->
          <!--            <span class="badge badge-soft-success mb-0">-->
          <!--                <i class="ri-arrow-up-line align-middle"></i> 15.72 %-->
          <!--            </span> vs. previous month</p>-->
        </div><!-- end col -->
        <div class="col-6">
          <div class="text-center">
            <img src="@/assets/images/illustrator-1.png" class="img-fluid" alt="">
          </div>
        </div><!-- end col -->
      </div><!-- end row -->
      <!--      <div class="mt-3 pt-2">-->
      <!--        <div class="progress progress-lg rounded-pill" v-for="(row, index) in this.sourcesData" :key="index">-->
      <!--          <div class="progress-bar bg-success" role="progressbar" :style="'width:' + (row.Qty / this.totalQty) * 100" :aria-valuenow="(row.Qty / this.totalQty) * 100"-->
      <!--               aria-valuemin="0" aria-valuemax="100"></div>-->
      <!--          <div class="progress-bar bg-info" role="progressbar" style="width: 18%" aria-valuenow="18" aria-valuemin="0"-->
      <!--               aria-valuemax="100"></div>-->
      <!--          <div class="progress-bar bg-success" role="progressbar" style="width: 22%" aria-valuenow="22"-->
      <!--               aria-valuemin="0" aria-valuemax="100"></div>-->
      <!--          <div class="progress-bar bg-warning" role="progressbar" style="width: 16%" aria-valuenow="16"-->
      <!--               aria-valuemin="0" aria-valuemax="100"></div>-->
      <!--          <div class="progress-bar bg-danger" role="progressbar" style="width: 19%" aria-valuenow="19" aria-valuemin="0"-->
      <!--               aria-valuemax="100"></div>-->
      <!--        </div>-->
      <!--      </div>&lt;!&ndash; end &ndash;&gt;-->

      <div class="mt-3 pt-2" v-for="(row, index) in this.sourcesData" :key="index">
        <div class="d-flex mb-2">
          <div class="flex-grow-1">
            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-primary me-2"></i> {{ row.sourceName }}
            </p>
          </div>
          <div class="flex-shrink-0 d-flex">
            <p class="mb-0"><b>{{ row.Qty }}</b></p>
            &nbsp; - &nbsp;
            <p class="mb-0"> {{ Math.round((row.Qty / this.totalQty) * 100).toFixed(2)}}%</p>
          </div>
        </div><!-- end -->
        <!--        <div class="d-flex mb-2">-->
        <!--          <div class="flex-grow-1">-->
        <!--            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-info me-2"></i>www.youtube.com-->
        <!--            </p>-->
        <!--          </div>-->
        <!--          <div class="flex-shrink-0">-->
        <!--            <p class="mb-0">17.51%</p>-->
        <!--          </div>-->
        <!--        </div>&lt;!&ndash; end &ndash;&gt;-->
        <!--        <div class="d-flex mb-2">-->
        <!--          <div class="flex-grow-1">-->
        <!--            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-success me-2"></i>www.meta.com-->
        <!--            </p>-->
        <!--          </div>-->
        <!--          <div class="flex-shrink-0">-->
        <!--            <p class="mb-0">23.05%</p>-->
        <!--          </div>-->
        <!--        </div>&lt;!&ndash; end &ndash;&gt;-->
        <!--        <div class="d-flex mb-2">-->
        <!--          <div class="flex-grow-1">-->
        <!--            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-warning me-2"></i>www.medium.com-->
        <!--            </p>-->
        <!--          </div>-->
        <!--          <div class="flex-shrink-0">-->
        <!--            <p class="mb-0">12.22%</p>-->
        <!--          </div>-->
        <!--        </div>&lt;!&ndash; end &ndash;&gt;-->
        <!--        <div class="d-flex">-->
        <!--          <div class="flex-grow-1">-->
        <!--            <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-danger me-2"></i>Other-->
        <!--            </p>-->
        <!--          </div>-->
        <!--          <div class="flex-shrink-0">-->
        <!--            <p class="mb-0">17.58%</p>-->
        <!--          </div>-->
        <!--        </div>&lt;!&ndash; end &ndash;&gt;-->
      </div><!-- end -->

    </div><!-- end card body -->
  </div><!-- end card -->
</template>