<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
// import loader from "../../../components/widgets/loader";

export default {
  props:['department'],
  data() {
    return {
      date3: moment().startOf('month').format("DD MMM, YYYY")  +  " to " + moment().endOf('month').format("DD MMM, YYYY"),
      rangeDateconfig: {
        mode: "range",
        dateFormat: "d M, Y",
        defaultDate: this.date3
      },
      sourcesData: [],
      totalQtyClients: 0,
      isLoading: false,
      totalQtyProspects: 0
    }
  },
  components: {
    flatPickr,
    // loader
  },
  methods:{
    async getRegistriesReport() {

      const data = {
        token: localStorage.getItem('jwt'),
        dateRange: this.date3,
        department: this.department
      }

      if(this.date3.length > 18){
        this.isLoading = true
        await axios
            .post('api/management/reportAgents', data)
            .then(response => {
              this.totalQtyClients = 0
              this.totalQtyProspects = 0
              this.sourcesData = response.data

              for (var row in this.sourcesData) {
                var value = this.sourcesData[row];
                this.totalQtyProspects += value.qtyProspects
                this.totalQtyClients += value.qtyClients
              }

              // response.data.forEach((row) => this.totalQtyProspects = row.qtyProspects)
              // response.data.forEach((row) => this.totalQtyProspects += parseInt(row.qtyProspects))
            })
            .catch(error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  this.$router.push('logout')
                }

                Swal.fire("Oops!", "Something was wrong, please contact your SysAdmin.", "error")
              }
            })
        this.isLoading = false

      }

    },
    numberWithCommas(x) {
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
      return x;
    }
  },
  async mounted(){
    await this.getRegistriesReport();
  }
}
</script>
<template>
<!--  <loader v-if="isLoading" :isLoading="isLoading" />-->
  <div class="card card-height-100">
    <div class="card-header align-items-center d-flex">
      <h4 class="card-title mb-0 flex-grow-1">Registries Added By Agent</h4>
      <div class="flex-shrink-0">
        <div class="dropdown card-header-dropdown">
          <flat-pickr
              v-model="date3"
              placeholder="Select date range"
              :config="rangeDateconfig"
              class="form-control bg-light border-light"
              @change="getRegistriesReport"
          >
          </flat-pickr>
        </div>
      </div>
    </div>
    <!-- end card header -->
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-6">
          <h6 class="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">Total Clients and Prospects Added</h6>
          <div class="row">
            <div class="col-md-6">
              <b>Clients</b>
              <h4 class="fs- mb-0">{{ this.totalQtyClients }}</h4>
            </div>
            <div class="col-md-6">
              <b>Prospects</b>
              <h4 class="fs- mb-0">{{ this.totalQtyProspects }}</h4>
            </div>
          </div>
          <!--          <p class="mb-0 mt-2 text-muted">-->
          <!--            <span class="badge badge-soft-success mb-0">-->
          <!--                <i class="ri-arrow-up-line align-middle"></i> 15.72 %-->
          <!--            </span> vs. previous month</p>-->
        </div><!-- end col -->
        <div class="col-6">
          <div class="text-center">
            <img src="@/assets/images/widget-img.png" class="img-fluid" alt="" width="120">
          </div>
        </div><!-- end col -->
      </div>
      <div class="table-responsive table-card mt-3 pt-2">
        <table
            class="
            table
            align-middle
            table-borderless table-centered table-nowrap
            mb-0
          ">
          <thead class="text-muted table-light">
          <tr>
            <th scope="col" style="width: 62%;">Agent Name</th>
            <th scope="col">Clients</th>
            <th scope="col">Prospects</th>
          </tr>
          </thead>
          <tbody>
          <tr  v-for="(row, index) in sourcesData" :key="index">
            <td>
              <a href="javascript:void(0);" class="link-secondary"
              >{{ row.name }}</a>
            </td>
            <td>{{  row.qtyClients  }}</td>
            <td>{{ row.qtyProspects }}</td>
          </tr>
          <!-- end -->
          </tbody>
          <!-- end tbody -->
        </table>
        <!-- end table -->
      </div>
      <!-- end -->
    </div>
    <!-- end cardbody -->
  </div>
  <!-- end card -->
</template>