<script>
import {AlertTriangleIcon} from "@zhuowenli/vue-feather-icons";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";

// import Widget from "../../views/dashboard/analytics/widget.vue";
// import AudiencesMetrics from "../../views/dashboard/analytics/audiences-metrics.vue";
// import AudiencesSessions from "../../views/dashboard/analytics/audiences-sessions.vue";
// import Country from "../../views/dashboard/analytics/country.vue";
import agentsAdded from "../management/plugins/agentsAdded";
// import SessionsCounties from "../../views/dashboard/analytics/sessions-countries.vue";
import registriesByAgent from "../management/plugins/registriesByAgent";
import axios from "axios";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
// import Users from "../../views/dashboard/analytics/users.vue";
export default {
  page: {
    title: "Analytics",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      title: "Analytics",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Analytics",
          active: true,
        },
      ],
      departmentList: {},
      department: '0',
    };
  },
  components: {
    AlertTriangleIcon,
    Layout,
    PageHeader,
    // Widget,
    // AudiencesSessions,
    // AudiencesMetrics,
    // Country,
    agentsAdded,
    // SessionsCounties,
    registriesByAgent
    // Users,
  },
  methods: {
    viewUserGroup(request) {
      const currentRole = jwt_decode(localStorage.getItem('jwt'))
      return currentRole.userGroup.includes(request)
    }
  },
  async mounted() {
    const data = {
      token: localStorage.getItem('jwt')
    }

    await axios
        .post('/api/listDepartments', data)
        .then(response => {
          this.departmentList = response.data
        })
        .catch(error => {
          if (error.response) {

            if (error.response.data.detail === 'Your session is expired!') {
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Departments could not be loaded, please contact your SysAdmin.", "error")
          }
        })

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-xxl-5">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body p-0">
                <div
                    class="
                    alert alert-warning
                    border-0
                    rounded-0
                    m-0
                    d-flex
                    align-items-center
                  "
                    role="alert"
                >
                  <alert-triangle-icon
                      class="text-danger me-2 icon-sm"
                  ></alert-triangle-icon>
                  <div class="flex-grow-1 text-truncate">
                    Important <b>information</b>
                  </div>
                  <div class="flex-shrink-0">
                  </div>
                </div>

                <div class="row align-items-end">
                  <div class="col-sm-8">
                    <div class="p-3">
                      <p class="fs-16 lh-base">
                        We're currently working hard adding more reports to make your agent tasks
                        <span class="fw-semibold">easier</span>. Thanks, T.I team. <i class="mdi mdi-arrow-right"></i>
                      </p>
                      <div class="mt-3">
                        <div class="col-lg-6" v-if="viewUserGroup('SUPER_ADMIN_GROUP')">
                          <label for="editDepartment" class="form-label">Department</label>
                          <select class="form-select mb-2"
                                  aria-label="Department"
                                  id="filterDepartment"
                                  name="filterDepartment"
                                  v-model="this.department">
                            <option value="0"> All</option>
                            <option v-for="(department, index) of departmentList"  :key="index"
                                    v-bind:value="department.departmentID"> {{ department.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="px-3">
                      <img
                          src="@/assets/images/user-illustarator-2.png"
                          class="img-fluid"
                          alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card-body-->
            </div>
          </div>
          <!-- end col-->
        </div>
        <!-- end row-->
<!--        <Widget/>-->
      </div>
<!--      <div class="col-xxl-7">-->
<!--        <div class="row h-100">-->
<!--          <div class="col-xl-6">-->
<!--            <Country/>-->
<!--          </div>-->
<!--          <div class="col-xl-6">-->
<!--            <SessionsCounties/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

<!--    <div class="row h-100">-->
<!--      <div class="col-xl-6">-->
<!--        <AudiencesMetrics/>-->
<!--      </div>-->
<!--      <div class="col-xl-6">-->
<!--        <AudiencesSessions/>-->
<!--      </div>-->
<!--    </div>-->

    <div class="row">
<!--      <div class="col-xl-4">-->
<!--        <Users/>-->
<!--      </div>-->
      <div class="col-xl-5 col-md-4">
        <agentsAdded :department="department"/>
      </div>
      <div class="col-xl-7 col-md-8">
        <registriesByAgent :department="department"/>
      </div>
    </div>
  </Layout>
</template>
