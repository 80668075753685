<script>
import {
  GoogleMap,
  // CustomControl
  Marker,
  MarkerCluster,
  InfoWindow,
  // CustomMarker
  // Polygon
} from "vue3-google-map";


import {EyeIcon, PhoneIcon} from "@zhuowenli/vue-feather-icons";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import notes from "@/components/users/notes.vue";
import moment from "moment/moment";
// import econtacts from "@/components/clients/econtacts.vue";


/**
 * Google-map component
 */
export default {
  page: {
    title: "Google Map",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    // econtacts,
    // notes,
    flatPickr,
    Layout,
    PageHeader,
    GoogleMap,
    // CustomControl
    Marker,
    // Polygon
    MarkerCluster,
    // CustomMarker,
    InfoWindow,
    EyeIcon,
    PhoneIcon,
    notes

  },
  data() {
    return {
      title: "Google Maps",
      items: [
        {
          text: "Maps",
          href: "/",
        },
        {
          text: "Google Maps",
          active: true,
        },
      ],
      markers: [],
      notesData: [],
      statusList: {},
      typeList: {},
      center : { lat: 29.782962, lng: -95.6693004 },
      clientData: [],
      departmentList: {},
      humanfriendlyConfig: {
        dateFormat: "F j, Y",
      },
      searchValues: {
        type: '0',
        keyword: '',
        language: '3',
        isProspect: window.location.href.match('/prospects/') ? 1 : 0,
        status: '0',
        sortBy: 'name',
        orderBy: 'ASC',
        dateRange: '0',
        department: '0' ,
        postalCode: '' ,
        toDate: new Date().toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) ,
        fromDate: new Date(new Date().setFullYear(new Date().getFullYear() - 4)).toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}),
      },
      selectedMarker: null,
      infoBoxOpen: false,
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y"
      },
    };
  },
  methods: {

    formatDate: function (date, format){
      date = moment(date).format(format)
      return date;
    },
    viewUserGroup(request){
        const currentRole = jwt_decode(localStorage.getItem('jwt'))
        return currentRole.userGroup.includes(request)
      },

    getCurrentLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback);
        } else {
          alert("Geolocation is not supported by this browser.");
        }
      },
    successCallback(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.center = { lat: latitude, lng: longitude }
      },

    async errorCallback(error) {
         console.error("Error getting location:", error.message);
         await Swal.fire("Error Getting your current location!", 'Please give CRM location Permissions', 'warning');
       },

    async getClientInfo (id) {
      this.openCreate = 0;
      document.getElementById('edit-btn').style.display='block';
      document.getElementById('add-btn').style.display='none'
      document.getElementById('agentModalLabel').innerHTML="Edit Client"

      const data = {
        token: localStorage.getItem('jwt'),
        ID: id
      }

      // document.getElementById('editNoteOpen').style.display='block';
      await this.getNotes(id)
      let clientInfo;

      await axios
          .post('/api/client/getClient', data)
          .then(response => {
            clientInfo = response.data;
            this.setClientData(response.data);
            localStorage.setItem('selectedUser', clientInfo.ID);
            localStorage.setItem('selectedUserName', clientInfo.firstName + ' ' + clientInfo.lastName);
            // this.getMapsLink()
          })
          .catch(error => {
            if (error.response) {

              if(error.response.data.detail === 'Your session is expired!'){
                this.$router.push('logout')
              }

              Swal.fire("Oops!", "Something was wrong, please contact your SysAdmin.", "error")
            }
          })
    },
    async getNotes (id = localStorage.getItem('selectedUser'), name) {
      const data = {
        token: localStorage.getItem('jwt'),
        userID: localStorage.getItem('id'),
        agentID: id,
        clientID: id,
      }
      localStorage.setItem('selectedUserName', name)
      localStorage.setItem('selectedUser', id)

      let url = '';
      url = '/api/client/getClientNotes';

      await axios
          .post(url, data)
          .then(response => {
            this.notesData = response.data;
          })
          .catch(error => {
            if (error.response) {
              Swal.fire("Oops!", "Notes could not be loaded, please contact your SysAdmin.", "error")
            }
          })
    },

    setClientData(clientInfo){

      this.clientData.email=clientInfo.email;
      this.clientData.status=clientInfo.status;
      this.clientData.firstName=clientInfo.firstName;
      this.clientData.lastName=clientInfo.lastName;
      this.clientData.language=clientInfo.language;
      this.clientData.phoneNumber=clientInfo.phoneNumber;
      this.clientData.gender=clientInfo.gender;
      this.clientData.address=clientInfo.address;
      this.clientData.birthday= this.formatDate(clientInfo.dateOfBirth, 'DD MMM, YYYY');
      this.clientData.interview= this.formatDate(clientInfo.interviewDate, 'MMMM D, YYYY H:mm A');
      this.clientData.age=clientInfo.age;
      this.clientData.type=(clientInfo.type !== '') ? clientInfo.type: clientInfo.leadType;
      this.clientData.city=clientInfo.city;
      this.clientData.departmentID=clientInfo.departmentID;
      this.clientData.leadType= clientInfo.leadType;
      this.clientData.state=clientInfo.state;
      this.clientData.country=clientInfo.country;
      this.clientData.postalCode=clientInfo.postalCode;
      this.clientData.createdBy=clientInfo.createdBy;
      this.clientData.insuranceLicense=clientInfo.isLicensed;
    },

    async updateListClientCoordinates() {

      let validate = true;
      let errors = [];
      let filterLanguage = document.getElementById("filterLanguage").value;
      let filterKeyword = document.getElementById("filterKeyword").value;
      let filterType = document.getElementById("filterType").value;
      // let filterFromDate = document.getElementById("filterFromDate").value;
      // let filterToDate = document.getElementById("filterToDate").value;

      if (filterLanguage !== '' && (filterLanguage === '1' || filterLanguage === '2')){
        this.searchValues.language = filterLanguage;
      }

      if (filterKeyword !== '' && filterKeyword.length >= 3) {
        this.searchValues.keyword = filterKeyword;
      }else if(filterKeyword !== '' && filterKeyword.length < 3) {
        errors.push('Keyword search should have 3 or more characters!')
        validate = false;
      }

      if(filterType !== ''){
        this.searchValues.type = filterType;
      }

      // var start = moment(this.formatDate(filterFromDate, 'YYYY-MM-DD'));
      // var end = moment(this.formatDate(filterToDate, 'YYYY-MM-DD'));
      // var days = end.diff(start, "days");
      //
      // if(days <= 366){
      //   this.searchValues.fromDate = filterFromDate;
      //   this.searchValues.toDate = filterToDate;
      // } else {
      //   errors.push("From Date and To Date (range) couldn't be larger than a year (365 days)!");
      //   validate = false;
      // }

      let typeAhead = document.getElementById('typeahead_id')

      const data = {
        token: localStorage.getItem('jwt'),
        isProspect: window.location.href.match('/prospects/') ? 1 : 0,
        searchValues: this.searchValues,
        userEmail: localStorage.getItem('email'),
        userID: localStorage.getItem('id'),
        deleted: 0,
        agentSearch: (typeAhead != null && typeof(typeAhead) != 'undefined' ) ? typeAhead.value : []
      }

      if(validate){
        await axios
            .post('/api/client/listClientCoordinates', data)
            .then(response => {
              this.markers = []
              response.data.forEach(item => {
                let array = {lat: parseFloat(item.lat), lng: parseFloat(item.lng), title: item.title, address: item.address,
                  phone: item.phoneNumber, id: item.ID}
                this.markers.push(array)
              })
            })
            .catch(error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  this.$router.push('logout')
                }

                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                }
              }
            })
      }else{
        let list = '';
        for (var i = 0; i < errors.length; i+=1) {
          list += errors[i] + ' \n';
        }

        await Swal.fire("" + list, '', 'info');

      }

    },

    voidPostalCodeMessage(){
      Swal.fire("Please write your Postal Code, bigger than 4 digits.", '', 'info');
    },

    async updateStatus() {

      let typeAhead = document.getElementById('typeahead_id')
      let data = {
        token: localStorage.getItem('jwt'),
        searchValues: this.searchValues,
        userEmail: localStorage.getItem('email'),
        userID: localStorage.getItem('id'),
        isProspect: this.searchValues.isProspect,
        deleted: 0,
        agentSearch: (typeAhead != null && typeof (typeAhead) != 'undefined') ? typeAhead.value : []
      }
      await axios
          .post('/api/client/clientStatus', data)
          .then(response => {
            this.statusList = response.data
          })
          .catch(error => {
            if (error.response) {

              if (error.response.data.detail === 'Your session is expired!') {
                this.$router.push('logout')
              }

              Swal.fire("Oops!", "Status could not be loaded, please contact your SysAdmin.", "error")
            }
          })
    },
    checkValueArray(needle,arr){
      return needle in arr
          ? arr[needle]
          : null;
    }
  },
  async mounted() {

    setInterval(() =>{

      console.log(this.searchValues.isProspect)

      if (window.location.href.match('/prospects/')){
        if (this.searchValues.isProspect !== 1){
          this.isProspect= 1
          this.searchValues.isProspect= 1
          this.updateStatus()
          this.markers = []
          this.searchValues.postalCode = ''
        }
      }else{
        if (this.searchValues.isProspect !== 0){
          this.isProspect= 0
          this.searchValues.isProspect= 0
          this.updateStatus()
          this.markers = []
          this.searchValues.postalCode = ''
        }
      }
      this.title =  this.searchValues.isProspect === 1 ? 'Google Maps | PROSPECTS' : 'Google Maps | CLIENTS';
    }, 3000);

    this.getCurrentLocation()

    let typeAhead = document.getElementById('typeahead_id')

    let data = {
      token: localStorage.getItem('jwt'),
      searchValues: this.searchValues,
      userEmail: localStorage.getItem('email'),
      userID: localStorage.getItem('id'),
      isProspect: window.location.href.match('/prospects/') ? 1 : 0,
      deleted: 0,
      agentSearch: (typeAhead != null && typeof(typeAhead) != 'undefined' ) ? typeAhead.value : []
    }

    setInterval(data.isProspect= window.location.href.match('/prospects/') ? 1 : 0, 3000);

    await axios
        .post('/api/listDepartments', data)
        .then(response => {
          this.departmentList=response.data
        })
        .catch(error => {
          if (error.response) {
            if(error.response.data.detail === 'Your session is expired!'){
              this.$router.push('logout')
            }
            Swal.fire("Oops!", "Departments could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    await axios
        .post('/api/client/clientStatus', data)
        .then(response => {
          this.statusList=response.data
        })
        .catch(error => {
          if (error.response) {

            if(error.response.data.detail === 'Your session is expired!'){
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Status could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    await axios
        .post('/api/client/clientTypes', data)
        .then(response => {
          this.typeList = response.data
        })
        .catch(error => {
          if (error.response) {

            if(error.response.data.detail === 'Your session is expired!'){
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Status could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    const loader = document.getElementById("loader");

    setTimeout(function() {
      loader.style.opacity = 0; // Fade out the loader
      document.body.classList.remove("loading"); // Remove the loading class from body
    }, 5500); // Adjust the delay as needed (in milliseconds)
  },

  created() {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card" id="leadsList">
              <div class="card-body border border-dashed border-end-0 border-start-0" >
                <form>
                  <div class="row g-3">
                    <div class="col-xxl-5 col-sm-6">
                      <div class="search-box">
                        <input
                            type="text"
                            class="form-control search"
                            placeholder="Search for..."
                            v-model="this.searchValues.keyword"
                            v-on:keyup.enter="this.searchValues.postalCode.length >= 4 ? updateListClientCoordinates(): voidPostalCodeMessage()"
                        />
                        <i class="ri-search-line search-icon"></i>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-xxl-2 col-sm-6">
                      <div>
                        <div class="input-group">
                          <input type="text"
                                 id="filterPostalCode"
                                 name="filterPostalCode"
                                 class="form-control search"
                                 placeholder="Enter PostalCode"
                                 v-model="this.searchValues.postalCode">
                          <button class="btn btn-outline-primary" type="button"
                                  id="button-addon2"
                                  @click=" this.searchValues.postalCode.length >= 4 ? updateListClientCoordinates(): voidPostalCodeMessage()"
                          >Search</button>
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-xxl-2 col-sm-4">
                    </div>
                    <!--end col-->
                    <div class="col-xxl-2 col-sm-4">
                    </div>
                    <!--end col-->
                    <div class="col-xxl-1 col-sm-4">
                      <button type="button" class="btn btn-soft-success float-end" href="#open-filters" data-bs-toggle="modal">
                        <i class="ri-filter-line align-bottom me-1"></i> Filters
                      </button>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
            </div>
            <GoogleMap
                api-key="AIzaSyBJXnhtk3_2A9SN4ExKh0m9UBlEJk3hsCU"
                style="width: 100%; height: 600px"
                :center="center"
                :zoom="10"
                :streetViewControl="false"
                :draggable="true"
            >
            <MarkerCluster>
              <Marker v-for="marker in markers"
                      :options="{ position: marker }"
                      :key="marker.id"
                      class="google-map-marker"
              >
                <InfoWindow>
                  <div id="content">
                    <h1 id="firstHeading" class="firstHeading">{{ marker.title }}</h1>
                    <div id="bodyContent">
                      <p>
                        <b>Address:</b> {{ marker.address}}
                        <br>
                        <b>Phone:</b> <a :href="'tel:'+marker.phone" style="line-height: 1.5;color: #7f8c8d;">{{ marker.phone}}</a>
                        <br>
                        <a :href="'https://maps.google.com/?q='+marker.address" target="_blank" style="line-height: 1.5;color: #25a0e2;"> View On Google Maps</a>
                      </p>

                      <b-list-group horizontal="md" class="justify-content-center" style="border-radius: 10px;">
                        <a :href="'tel:'+marker.phone"><BListGroupItem><PhoneIcon class="grayIcon eyeIcon"></PhoneIcon> Call</BListGroupItem></a>
                        <a @click="getClientInfo(marker.id)" data-bs-toggle="modal" href="#clientModal" data-bs-trigger="hover" data-bs-placement="top"><BListGroupItem><EyeIcon class="grayIcon eyeIcon"></EyeIcon> View</BListGroupItem></a>
                      </b-list-group>
                    </div>
                  </div>
                </InfoWindow>
              </Marker>
            </MarkerCluster>
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>

    <!-- Filters Modal -->
    <div id="open-filters" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;" data-bs-focus="false">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel">Filters</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6">
                <label for="filterKeyword" class="form-label">Keyword</label>
                <input type="text" id="filterKeyword" name="filterKeyword" class="form-control mb-2" placeholder="Enter Keyword" v-model="this.searchValues.keyword">
              </div>
              <div class="col-lg-6">
                <label for="filterPostalCode" class="form-label">Postal Code</label>
                <input type="text"
                       id="filterPostalCode"
                       name="filterPostalCode"
                       class="form-control search"
                       placeholder="Enter PostalCode"
                       v-model="this.searchValues.postalCode">
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterType" class="form-label">Source</label>
                <select class="form-select mb-2" aria-label="Status" id="filterType" name="filterType" v-model="this.searchValues.type">
                  <option value="0">All</option>
                  <option v-for="(type, typeIndex) of typeList" :key="typeIndex" v-bind:value="type.name">{{ type.name }}</option>
                </select>
              </div>
              <div class="col-lg-6">
                <label for="filterStatus" class="form-label">Status</label>
                <select class="form-select mb-2" aria-label="Status" id="filterStatus" v-model="this.searchValues.status">
                  <option value="0">All</option>
                  <option v-for="(status, statusIndex) of statusList" :key="statusIndex" v-bind:value="status.statusID">{{ status.name }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="editLanguage" class="form-label">Language</label>
                <select class="form-select mb-2" aria-label="Language" id="filterLanguage" name="filterLanguage" v-model="this.searchValues.language">
                  <option value="1">English</option>
                  <option value="2">Spanish</option>
                  <option value="3">Both</option>
                </select>
              </div>
              <div class="col-lg-6">
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterFromDate" class="form-label"> From date </label>
                <flat-pickr
                    v-model="this.searchValues.fromDate"
                    :config="humanfriendlyConfig"
                    class="form-control flatpickr-input"
                    id="filterFromDate"
                    name="filterFromDate"
                ></flat-pickr>
              </div>
              <div class="col-lg-6">
                <label for="filterToDate" class="form-label"> To Date </label>
                <flat-pickr
                    v-model="this.searchValues.toDate"
                    :config="humanfriendlyConfig"
                    class="form-control flatpickr-input"
                    id="filterToDate"
                    name="filterToDate"
                ></flat-pickr>
              </div>
            </div>
            <div class="row">

            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterDateRange" class="form-label">Time Range</label>
                <select class="form-select mb-2" id="filterDateRange" name="filterDateRange" v-model="this.searchValues.dateRange">
                  <option value="0" selected>...</option>
                  <option value="1">Until 2 year Ago</option>
                  <option value="2">Until 4 year Ago</option>
                  <option value="3">Until 5 year Ago</option>
                  <option value="4">Begin of times</option>
                </select>
              </div>
              <div class="col-lg-6">
                <span v-if="viewUserGroup('SUPER_ADMIN_GROUP')">
                  <label for="filterDateRange" class="form-label">Department</label>
                    <select class="form-select mb-2" aria-label="Department" id="filterDepartment" name="filterDepartment"
                            v-model="this.searchValues.department">
                        <option value="0"> All</option>
                        <option v-for="(department, index) of departmentList"  :key="index"
                                v-bind:value="department.departmentID"> {{ department.name }}
                        </option>
                    </select>
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click=" this.searchValues.postalCode.length >= 4 ? updateListClientCoordinates(): voidPostalCodeMessage()">Search</button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->

    <!--  Add client modal  -->
    <div class="modal zoomIn" id="clientModal" tabindex="-1" aria-labelledby="agentModalLabel" aria-hidden="true" data-bs-focus="false">
      <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width: 1000px;">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="agentModalLabel"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    id="close-modal"></button>
          </div>
          <form id="addform" @submit.prevent="" autocomplete="off">
            <div class="modal-body">
              <h6> <span style="color:red; font-size: 0.7em;">Required fields (*)</span></h6>
              <input type="hidden" id="id"/>
              <div class="row g-3">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-8">
                      <label for="editEmail" class="form-label">Email</label>
                      <input type="text" id="editEmail" name="editEmail" class="form-control mb-2 createEmail" placeholder="Enter Email"
                             v-model="this.clientData.email" autocomplete="off">
                      <div class="invalid-feedback">
                        This email is already in use.
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <label for="editCreatedBy" class="form-label">Created By</label>
                      <input type="text" id="editCreatedBy" name="editCreatedBy" class="form-control mb-2" disabled v-model="this.clientData.createdBy"  autocomplete="off">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="editFirstName" class="form-label">First Name <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <input type="text" id="editFirstName" name="editFirstName" class="form-control mb-2" placeholder="Enter First Name" v-model="this.clientData.firstName" required  autocomplete="off">
                    </div>
                    <div class="col-lg-4">
                      <label for="editLastName" class="form-label">Last Name <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <input type="text" id="editLastName" name="editLastName" class="form-control mb-2" placeholder="Enter Last Name" v-model="this.clientData.lastName" required  autocomplete="off">
                    </div>
                    <div class="col-lg-4">
                      <label for="editGender" class="form-label"> Gender <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <br>
                      <div class="form-check-success form-check-inline" style="margin-top: 0.8em;">
                        <input class="form-check-input" type="radio" name="editGender" id="editGender" value="M" v-model="this.clientData.gender"/>
                        <label class="form-check-label" for="editGender"> &nbsp; Male</label>
                      </div>
                      <div class="form-check-success form-check-inline" style="margin-top: 0.8em;">
                        <input class="form-check-input" type="radio" name="editGender" id="editGender2" value="F" v-model="this.clientData.gender"/>
                        <label class="form-check-label" for="editGender2"> &nbsp; Female</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="editLanguage" class="form-label">Language <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <select class="form-select mb-2" aria-label="Language" id="editLanguage" name="editLanguage" v-model="this.clientData.language" required>
                        <option value="1" selected>English</option>
                        <option value="2">Spanish</option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <label for="editStatus" class="form-label">Status <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <select class="form-select mb-2" aria-label="Status" id="editStatus" name="editStatus" v-model="this.clientData.status">
                        <option v-for="(status, statusIndex) of statusList" :key="statusIndex" v-bind:value="status.statusID">{{ status.name }}</option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <label for="editType" class="form-label">client Source <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <select class="form-select mb-2" aria-label="Status" id="editType" name="editType" v-model="this.clientData.leadType">
                        <option v-for="(type, typeIndex) of typeList" :key="typeIndex" v-bind:value="type.name">{{ type.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="editPhoneNumber" class="form-label">Phone <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <input type="text" id="editPhoneNumber" name="editPhoneNumber" class="form-control mb-2" placeholder="Phone number" required v-model="this.clientData.phoneNumber"/>
                    </div>
                    <div class="col-lg-4">
                      <label for="editDepartment" class="form-label">Department <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <select class="form-select mb-2" aria-label="Department" id="editDepartment" name="editDepartment"
                              v-model="this.clientData.departmentID" v-if="(departmentList.length > 1)">
                        <option v-for="(department, index) of departmentList"  :key="index"
                                v-bind:value="department.departmentID">{{ department.name }}
                        </option>
                      </select>
                      <select class="form-select mb-2" aria-label="Department" id="editDepartment" name="editDepartment"
                              v-model="this.clientData.departmentID" v-else>
                        <option v-bind:value="departmentList.departmentID">{{ departmentList.name }}</option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <label for="editInterview" class="form-label mb-2">Interview</label>
                      <flat-pickr
                          v-model="this.clientData.interview"
                          :config="dateTimeConfig"
                          id="editInterview"
                          name="editInterview"
                          class="form-control"
                          data-provider="timepickr"
                          data-time-hrs="true"
                      ></flat-pickr>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-8">
                      <label for="editAddress" class="form-label">Address</label>&nbsp;<code><a href="#" target="_blank" id="gMapsLink">Google Maps </a></code>
                      <input type="text" id="editAddress" name="editAddress" class="form-control mb-2" placeholder="Enter Address" required v-model="this.clientData.address"/>
                    </div>
                    <div class="col-lg-4">
                      <label for="editBirthdate" class="form-label"> Birthdate </label>
                      <flat-pickr
                          v-model="this.clientData.birthday"
                          :config="timeConfig"
                          class="form-control flatpickr-input"
                          id="editBirthdate"
                          name="editBirthdate"
                      ></flat-pickr>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2">
                      <label for="editState" class="form-label">State</label>
                      <input type="text" id="editState" name="editState" class="form-control mb-2" placeholder="Enter State" required v-model="this.clientData.state"/>
                    </div>
                    <div class="col-lg-3">
                      <label for="editCountry" class="form-label">Country</label>
                      <input type="text" id="editCountry" name="editCountry" class="form-control mb-2" placeholder="Enter Country" required v-model="this.clientData.country"/>
                    </div>
                    <div class="col-lg-3">
                      <label for="editCity" class="form-label">City</label>
                      <input type="text" id="editCity" name="editCity" class="form-control mb-2" placeholder="Enter City" required v-model="this.clientData.city"/>
                    </div>
                    <div class="col-lg-4">
                      <label for="editPostalCode" class="form-label">Postal code</label>
                      <input type="text" id="editPostalCode" name="editPostalCode" class="form-control mb-2" placeholder="Enter Address" required v-model="this.clientData.postalCode"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12" style="margin-top: 10px;">
                      <div class="card">
                        <div class="card-body checkout-tab">
                          <form action="#">
                            <div class="step-arrow-nav mt-n3 mx-n3 mb-3">
                              <ul class="nav nav-pills nav-justified custom-nav" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <button
                                      class="nav-link fs-15 p-3 active"
                                      id="pills-bill-notes-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-pills-notes"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-pills-notes"
                                      aria-selected="true"
                                  >
                                    <i
                                        class="
                                      ri-sticky-note-line
                                      fs-16
                                      p-2
                                      bg-soft-primary
                                      text-primary
                                      rounded-circle
                                      align-middle
                                      me-2
                                    "
                                    ></i>
                                    Notes
                                  </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button
                                      class="nav-link fs-15 p-3 disabled"
                                      id="pills-econtact"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-econtact-tab"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-econtact-tab"
                                      aria-selected="false"
                                  >
                                    <i
                                        class="
                        ri-alarm-warning-line
                        fs-16
                        p-2
                        bg-soft-primary
                        text-primary
                        rounded-circle
                        align-middle
                        me-2
                      "
                                    ></i>
                                    E. Contacts (disabled)
                                  </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button
                                      class="nav-link fs-15 p-3 disabled"
                                      id="pills-payment-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#pills-payment"
                                      type="button"
                                      role="tab"
                                      aria-controls="pills-payment"
                                      aria-selected="false"
                                  >
                                    <i
                                        class="
                                              ri-folder-upload-line
                                              fs-16
                                              p-2
                                              bg-soft-primary
                                              text-primary
                                              rounded-circle
                                              align-middle
                                              me-2
                                            "
                                    ></i>
                                    Upload Files (disabled)
                                  </button>
                                </li>
                              </ul>
                            </div>

                            <div class="tab-content">
                              <div
                                  class="tab-pane fade show active"
                                  id="pills-pills-notes"
                                  role="tabpanel"
                                  aria-labelledby="pills-notes"
                              >
                                <div>
                                  <div class="row" id="editNoteOpen">
                                    <notes :dataList="notesData" @onFire="getNotes()" :idNumber="1"> </notes>
                                  </div>
                                </div>
                              </div>
                              <!-- end tab pane -->

                              <div
                                  class="tab-pane fade"
                                  id="pills-econtact-tab"
                                  role="tabpanel"
                                  aria-labelledby="pills-econtact"
                              >
                              </div>
                              <!-- end tab pane -->

                              <div
                                  class="tab-pane fade"
                                  id="pills-payment"
                                  role="tabpanel"
                                  aria-labelledby="pills-payment-tab"
                              >
                                <div>
                                  <div class="col-12 text-center">
                                    <h1 class="mb-3">Upload Files</h1>
                                    <h6><b>Allowed Files:</b> <p>.jpg, .png, .docx, .xls, .jpeg, .pdf</p></h6>
                                    <h6><b>Max. File Size:</b> <p>5 mb</p></h6>
                                  </div>

                                  <div class="col-md-12">
                                    <form enctype='multipart/form-data'>
                                      <div class="form-group">
                                        <label for="uploadFiles">Select Image</label>
                                      </div>
                                    </form>
                                    <div class="table-responsive" style="margin-top: 20px;">
                                      <button type="button" class="btn btn-soft-success float-end"
                                              style="margin-bottom: 10px;"> Refresh File List </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- end tab pane -->
                            </div>
                            <!-- end tab content -->
                          </form>
                        </div>
                        <!-- end card body -->
                      </div>
                      <!-- end card -->
                    </div>
                    <!-- end col -->
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>

            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" id="closemodal" data-bs-dismiss="modal">Close </button>
                <button type="submit" class="btn btn-success" id="add-btn" @click="createClient">Save </button>
                <button type="button" class="btn btn-success" id="edit-btn" @click="updateClient">Update </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
#content {
  margin: 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

#firstHeading.firstHeading {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  color: #2c3e50;
}

#bodyContent {
  line-height: 1.5;
  color: #7f8c8d;
}

#bodyContent b {
  color: #2c3e50;
}
</style>