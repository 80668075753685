<template>
<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{new Date().getFullYear()}} © With ❤️ from DMB Insurance Group - T.I Department.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                  <a href="#">
                    Terms & conditions
                  </a>
                </div>
            </div>
        </div>
    </div>
</footer>
</template>
