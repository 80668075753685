<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.scss";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import Swal from "sweetalert2";
import Lottie from "@/components/widgets/lottie.vue";
import axios from "axios";
import animationData from "@/components/widgets/msoeawqm.json";
import calendar from "../../components/appoinmentCalendar";
import jwt_decode from "jwt-decode";

// components
import notes from '../../components/users/notes';
import Papa from "papaparse";

import loader from "../../components/widgets/loader";

export default {
  page: {
    title: "Agents",
    meta: [{name: "description", content: appConfig.description}],
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect,
    flatPickr,
    notes,
    calendar,
    loader
  },
  data() {
    return{
      title: "Agent List",
      items: [
        {
          text: "Agents",
          href: "/",
        },
        {
          text: "Agent List",
          active: true,
        },
      ],
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y"
      },
      dateTimeConfig: {
        enableTime: true,
        dateFormat: "F j, Y H:i K"
      },
      list: [],
      listQty: 0,
      agentData: {
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        language: '',
        status: '',
        phone: '',
        department: '',
        state: '',
        birthDate: '',
        address: '',
        country: '',
        zipCode: '',
        interview: '',
        agentNumber: '',
        createdBy: '',
        insuranceLicense: '',
        licenseNumber: '',
        note: ''
      },
      departmentList: {},
      statusList: {},
      sourcesList: {},
      statesList: {},
      typeList: {},
      page: 1,
      perPage: 100,
      pages: [],
      searchQuery: null,
      humanfriendlyConfig: {
        dateFormat: "F j, Y",
      },
      data: {
        token: localStorage.getItem('jwt'),
        searchValues: {
          limitTo: this.perPage,
          source: '0',
          isActive: 1,
          noteSearch: '',
          keyword: '',
          licensed: 1,
          language: 3,
          status: 0,
          sortBy: 'name',
          orderBy: 'ASC',
          dateRange: 0,
          statesList: 'ALL',
          department: '0',
          toDate: new Date().toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}) ,
          fromDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}),
        }
      },
      defaultOptions: { animationData: animationData },
      notesData: [],
      idNumber: 1,
      password: '',
      confirmPasword: '',
      receiveNotifications:1,
      file: [],
      content: [],
      spectedColumnsindeed: [
        "nombre",
        "email",
        "teléfono",
        "estado",
        "ubicación del candidato",
        "puesto actual",
        "educación",
        "título del empleo",
        "ubicación del empleo",
        "fecha",
        "Nivel de interés",
        "Fuente",
      ],
      parsed: false,
      selectedDepartmentForAgentList: 0,
      isLoading: false,
      mobileView: true
    }
  },
  watch: {
    companies() {
      this.setPages();
    },
  },
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    editdata(data){

      document.getElementById('id').value= data.id
      document.getElementById('customername').value=data.name;
      document.getElementById('companyname').value=data.company;
      document.getElementById('leadsscore').value=data.score;
      document.getElementById('phone').value=data.phone;
      document.getElementById('location').value=data.location;
      document.getElementById('date').value=data.date;
    },
    async updateAgent() {

      this.errors = []

      if (!document.getElementById('editEmail').value) {
        this.errors.push('Email is required.');
      } else if (!this.validEmail(document.getElementById('editEmail').value)) {
        this.errors.push('Valid email is required.');
      }

      if (!document.getElementById('editFirstName').value) {
        this.errors.push('First Name is required.');
      }

      if (!document.getElementById('editLastName').value) {
        this.errors.push('Last Name is required.');
      }

      if (!document.getElementById('editLanguage').value) {
        this.errors.push('Language is required.');
      }

      if (!document.getElementById('editStatus').value) {
        this.errors.push('Status is required.');
      }

      if (!document.getElementById('editPhoneNumber').value) {
        this.errors.push('Phone is required.');
      }

      if(this.validPhone(document.getElementById('editPhoneNumber').value) === false && document.getElementById('editPhoneNumber').value !== ''){
        this.errors.push('Please, write a valid phone number!');
      }

      if (!document.getElementById('editDepartment').value) {
        this.errors.push('Department is required.');
      }

      if (!document.getElementById('editState').value) {
        this.errors.push('State is required.');
      }

      if (!document.getElementById('editBirthDate').value) {
        this.errors.push('Birth date is required.');
      }

      if (!document.getElementById('editAddress').value) {
        this.errors.push('Address is required.');
      }

      if (!document.getElementById('editAgentNumber').value && document.getElementById('editStatus').value === 5) {
        this.errors.push('Agent Number is required.');
      }

      if ((!document.getElementById('editLicensed').value && document.getElementById('editStatus').value === 5) || !document.getElementById('editLicensed').value) {
        this.errors.push('Insurance Licensed? is required.');
      }

      if ((!document.getElementById('editLicensed').value && document.getElementById('editStatus').value === 5) || (!this.agentData.licenseNumber && document.getElementById('editLicensed').value === 1)) {
        this.errors.push('License number is required.');
      }

      if (!this.errors.length) {
        const data = {
          username: '',
          email: document.getElementById('editEmail').value.toLowerCase(),
          firstName: document.getElementById('editFirstName').value,
          lastName: document.getElementById('editLastName').value,
          language: document.getElementById('editLanguage').value,
          status: document.getElementById('editStatus').value,
          phoneNumber: document.getElementById('editPhoneNumber').value,
          departmentID: document.getElementById('editDepartment').value,
          state: document.getElementById('editState').value,
          source: document.getElementById('editSource').value,
          city: document.getElementById('editCity').value,
          birthDate: this.formatDate(document.getElementById('editBirthDate').value, 'DD MMM, YYYY'),
          address: document.getElementById('editAddress').value,
          country: document.getElementById('editCountry').value,
          postalCode: document.getElementById('editPostalCode').value,
          interviewDate: document.getElementById('editInterview').value,
          agentNumber: document.getElementById('editAgentNumber').value,
          createdBy: localStorage.getItem('email'),
          isLicensed: document.getElementById('editLicensed').value,
          licenseNumber: this.agentData.licenseNumber,
          token: localStorage.getItem('jwt'),
          userID: localStorage.getItem('id'),
          agentID: document.getElementById('id').value
        }

        this.isLoading = true;

        await axios
            .put('/api/updateAgent', data)
            .then(async response => {
              if (response) {
                document.getElementById('closemodal').click();
                await Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Agent update successfully!'
                });
              }
            })
            .catch(async error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  await this.$router.push('logout')
                }

                let list = '';
                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                  list += '\n' + '<b>' + error.response.data[property] + '</b>';
                }

                await Swal.fire({
                  title: "Please check the following: " + list,
                  icon: 'warning',
                  confirmButtonClass: "btn btn-info",
                  buttonsStyling: false,
                  showCloseButton: true,
                });

              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      } else {
        let list = '';
        for (let i = 0; i < this.errors.length; i++)
          list += '\n' + '<b>' + this.errors[i] + '</b>';

        await Swal.fire({
          title: "Please complete the form! " + list,
          icon: 'warning',
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
      this.isLoading = false;
    },
    addNewAgentModal() {
      this.resetAgentData()
      document.getElementById("addform").reset();
      document.getElementById('agentModalLabel').innerHTML="Add Agent";
      document.getElementById('add-btn').style.display='block';
      document.getElementById('edit-btn').style.display='none';
      document.getElementById('editNoteOpen').style.display='none';
      document.getElementById("gMapsLink").href = '#';
      document.getElementById('editNotes').style.display='block';
    },
    async createAgent() {
      this.errors = []

      if (!this.agentData.email) {
        this.errors.push('Email is required.');
      } else if (!this.validEmail(this.agentData.email)) {
        this.errors.push('Valid email is required.');
      }

      if (!this.agentData.firstName) {
        this.errors.push('First Name is required.');
      }

      if (!this.agentData.lastName) {
        this.errors.push('Last Name is required.');
      }

      if (!this.agentData.language) {
        this.errors.push('Language is required.');
      }

      if (!this.agentData.status) {
        this.errors.push('Status is required.');
      }

      if (!this.agentData.phone) {
        this.errors.push('Phone is required.');
      }

      if(this.validPhone(this.agentData.phone) === false && this.agentData.phone !== ''){
        this.errors.push('Please, write a valid phone number!');
      }

      if (!this.agentData.department) {
        this.errors.push('Department is required.');
      }

      if (!document.getElementById('editState').value) {
        this.errors.push('State is required.');
      }

      if (!this.agentData.birthDate) {
        this.errors.push('Birth date is required.');
      }

      if (!this.agentData.address) {
        this.errors.push('Address is required.');
      }

      if (!this.agentData.agentNumber && this.agentData.status === 5) {
        this.errors.push('Agent Number is required.');
      }

      if ((!this.agentData.insuranceLicense && this.agentData.status === 5) || !this.agentData.insuranceLicense) {
        this.errors.push('is Insurance Licensed? is required.');
      }

      if ((!this.agentData.licenseNumber && this.agentData.status === 5) || (!this.agentData.licenseNumber && this.agentData.insuranceLicense === 1)) {
        this.errors.push('License number is required.');
      }

      if(!this.errors.length) {
        const data = {
          username:  '',
          email:  this.agentData.email.toLowerCase(),
          firstName:  this.agentData.firstName,
          lastName:  this.agentData.lastName,
          language:  this.agentData.language,
          status:  this.agentData.status,
          source:  this.agentData.source,
          phoneNumber:  this.agentData.phone,
          departmentID:  this.agentData.department,
          state:  this.agentData.state,
          city:  this.agentData.city,
          birthDate: this.formatDate(this.agentData.birthDate, 'DD MMM, YYYY'),
          address:  this.agentData.address,
          country:  this.agentData.country,
          postalCode:  this.agentData.zipCode,
          interviewDate:  this.agentData.interview,
          agentNumber:  this.agentData.agentNumber,
          createdBy:  localStorage.getItem('email'),
          isLicensed:  this.agentData.insuranceLicense,
          note: this.agentData.note,
          licenseNumber: this.agentData.licenseNumber,
          token: localStorage.getItem('jwt'),
          userID: localStorage.getItem('id')
        }

        await axios
            .post('/api/createAgent', data)
            .then(async response => {
              document.getElementById("addform").reset();
              if (response.data['ID'] !== null) {
                document.getElementById('closemodal').click();
                await Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Agent created successfully!'
                });
              }
            })
            .catch(async error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  await this.$router.push('logout')
                }

                let list = '';
                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                  list += '\n' + '<b>' + error.response.data[property] + '</b>';
                }

                await Swal.fire({
                  title: "Please check the following: " + list,
                  icon: 'warning',
                  confirmButtonClass: "btn btn-info",
                  buttonsStyling: false,
                  showCloseButton: true,
                });

              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      } else {
        let list = '';
        for (let i = 0; i < this.errors.length; i++)
          list += '\n' + '<b>' + this.errors[i]+ '</b>';

        await Swal.fire({
          title: "Please complete the form! " + list,
          icon: 'warning',
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async getDepartments() {

      await axios
          .post('/api/getDepartments')
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            if (error.response) {

              if(error.response.data.detail === 'Your session is expired!'){
                this.$router.push('logout')
              }

              Swal.fire("Oops!", "Something was wrong, please contact your SysAdmin.", "error")
            }
          })
    },
    async getUserInfo(id) {
      document.getElementById('edit-btn').style.display='block';
      document.getElementById('add-btn').style.display='none'
      document.getElementById('agentModalLabel').innerHTML="Edit Agent"

      const data = {
        token: localStorage.getItem('jwt'),
        ID: id
      }

      document.getElementById('editNoteOpen').style.display='block';
      document.getElementById('editNotes').style.display='none';
      await this.getNotes(id)
      let agentInfo;

      await axios
          .post('/api/getUser', data)
          .then(response => {
            agentInfo=response.data
            this.setAgentData(agentInfo);
            document.getElementById('id').value=agentInfo.ID
            document.getElementById('editEmail').value=agentInfo.email;
            document.getElementById('editFirstName').value=agentInfo.firstName;
            document.getElementById('editLastName').value=agentInfo.lastName;
            document.getElementById('editLanguage').value=agentInfo.language;
            document.getElementById('editStatus').value=agentInfo.status
            document.getElementById('editPhoneNumber').value= agentInfo.phoneNumber;
            document.getElementById('editDepartment').value=agentInfo.departmentID;
            document.getElementById('editBirthDate').value=agentInfo.dateOfBirth;
            document.getElementById('editAddress').value=agentInfo.address;
            document.getElementById('editState').value=agentInfo.state;
            document.getElementById('editCountry').value=agentInfo.country;
            document.getElementById('editPostalCode').value=agentInfo.postalCode;
            document.getElementById('editAgentNumber').value=agentInfo.agentNumber;
            document.getElementById('editCreatedBy').value=agentInfo.createdBy;
            document.getElementById('editLicensed').value=agentInfo.isLicensed;
            document.getElementById('editInterview').value= this.formatDate(agentInfo.interviewDate, 'MMMM DD, YYYY H:mm A');
            this.getMapsLink()
          })
          .catch(error => {
            if (error.response) {

              if(error.response.data.detail === 'Your session is expired!'){
                this.$router.push('logout')
              }

              Swal.fire("Oops!", "Something was wrong, please contact your SysAdmin.", "error")
            }
          })
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.trim());
    },
    validPhone: function (phone) {
      return /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm.test(phone);
    },
    formatDate: function(date, format){
      date = moment(date).format(format)

      if(date !== 'Invalid date'){
        return date;
      }else{
        return ' '
      }

    },
    formatPhone: function(phone, flag=0) {

      // if flag == 1 put value in phone Edit

      if(phone !== undefined){
        if (phone.length > 10 ){
          phone = phone.substring(1);
        }

        const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      }else{
        phone = ''
      }

      if(flag !== 1){
        return phone;
      }else{
        document.getElementById('editPhoneNumber').value = phone;
      }
    },
    setAgentData: function(data) {
      this.agentData.email=data.email;
      this.agentData.firstName=data.firstName;
      this.agentData.lastName=data.lastName;
      this.agentData.language=data.language;
      this.agentData.status=data.status
      this.agentData.source=data.source
      this.agentData.phone=data.phoneNumber;
      this.agentData.department=data.departmentID
      this.agentData.birthDate= this.formatDate(data.dateOfBirth, 'DD MMM, YYYY');
      this.agentData.interview=  this.formatDate(data.interviewDate, 'MMMM DD, YYYY H:MM A');
      this.agentData.address=data.address;
      this.agentData.state=data.state;
      this.agentData.city=data.city;
      this.agentData.country=data.country;
      this.agentData.zipCode=data.postalCode;
      this.agentData.agentNumber=data.agentNumber;
      this.agentData.createdBy=data.createdBy;
      this.agentData.insuranceLicense=data.isLicensed;
      this.agentData.licenseNumber=data.licenseNumber;
    },
    resetAgentData: function(){
      this.agentData =
          {username: '',
          email: '',
          firstName: '',
          lastName: '',
          language: '',
          status: '',
          phone: '',
          department: '',
          state: '',
          birthDate: '',
          address: '',
          country: '',
          zipCode: '',
          interview: '',
          agentNumber: '',
          createdBy: '',
          insuranceLicense: '',
          licenseNumber: '',
          note: ''
          }
    },
    async updateAgentList(status='0') {

      let validate = true;
      let errors = [];
      let filterLanguage = document.getElementById("filterLanguage").value;
      let filterKeyword = document.getElementById("filterKeyword").value;
      let filterLicensed = document.getElementById("filterLicensed").value;
      let filterNotes = document.getElementById("filterNotes").value;
      let filterFromDate = document.getElementById("filterFromDate").value;
      let filterToDate = document.getElementById("filterToDate").value;
      let isActive = document.querySelector('#isActive').checked;

      if (status !== '0') {
        this.data.searchValues.status = status
      } else {
        this.data.searchValues.status = document.getElementById("filterStatus").value
      }

      if(isActive === true) {
        this.data.searchValues.isActive = 1;
      } else {
        this.data.searchValues.isActive = 0;
      }

      if (filterLanguage !== '' && (filterLanguage === '1' || filterLanguage === '2')){
        this.data.searchValues.language = filterLanguage;
      }

      if (filterKeyword !== '' && filterKeyword.length >= 3) {
        this.data.searchValues.keyword = filterKeyword;
      } else if (filterKeyword !== '' && filterKeyword.length < 3) {
        errors.push('Keyword search should have 3 or more characters!')
        validate = false;
      }

      if(filterLicensed !== ''){
        this.data.searchValues.licensed = filterLicensed;
      }

      if (filterNotes !== '' && filterNotes.length >= 3) {
        this.data.searchValues.noteSearch = filterNotes;
      }else if(filterNotes !== '' && filterNotes.length < 3) {
        errors.push('Notes search should have 3 or more characters!')
        validate = false;
      }

      var start = moment(this.formatDate(filterFromDate, 'YYYY-MM-DD'));
      var end = moment(this.formatDate(filterToDate, 'YYYY-MM-DD'));
      var days = end.diff(start, "days");

      if(days <= 366){
        this.data.searchValues.fromDate = filterFromDate;
        this.data.searchValues.toDate = filterToDate;
      } else {
        errors.push("From Date and To Date (range) couldn't be larger than a year (365 days)!");
        validate = false;
      }

      const data = {
        token: localStorage.getItem('jwt'),
        isProspect: 0,
        searchValues: this.data.searchValues,
        userEmail: localStorage.getItem('email'),
        userID: localStorage.getItem('id'),
      }

      if(validate){
        axios
            .post('/api/listAgents', data)
            .then(response => {
              this.list=response.data
              this.listQty = this.list.length
              this.page = 1
              this.setPages()
            })
            .catch(error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  this.$router.push('logout')
                }
                // for (const property in error.response.data) {
                // this.errors.push(`${property}: ${error.response.data[property]}`)
                // }
              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      }else{
        let list = '';
        for (var i = 0; i < errors.length; i+=1) {
          list += errors[i] + ' \n';
        }

        await Swal.fire("" + list, '', 'info');

      }

    },
    setPages() {

      let numberOfPages = Math.ceil(this.list.length / this.perPage);
      this.pages = []
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(companies) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return companies.slice(from, to);
    },
    filterStatus(status){
      var statusButton= document.getElementById(status);
      statusButton.click();
    },
    deleteUser(ID) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger ml-2",
          cancelButton: "btn btn-success",
        },
        buttonsStyling: false,
      });
      localStorage.setItem('selectedUser', ID)
      swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {

              const data = {
                token: localStorage.getItem('jwt'),
                userID: localStorage.getItem('id'),
                userName: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastname'),
                userEmail: localStorage.getItem('email'),
                agentID: localStorage.getItem('selectedUser')
              }

              axios
                  .put('/api/deleteUser', data)
                  .then(async response => {
                    if (response) {
                      await this.updateAgentList()
                      await swalWithBootstrapButtons.fire(
                          "Deleted!",
                          "Agent has been deleted.",
                          "success"
                      );
                    }
                  })
                  .catch(async error => {
                    if (error.response) {
                      let list = '';
                      for (const property in error.response.data) {
                        this.errors.push(`${property}: ${error.response.data[property]}`)
                        list += '\n' + '<b>' + error.response.data[property] + '</b>';
                      }

                      await Swal.fire({
                        title: "Please check the following: " + list,
                        icon: 'warning',
                        confirmButtonClass: "btn btn-info",
                        buttonsStyling: false,
                        showCloseButton: true,
                      });

                    } else if (error.message) {
                      this.errors.push('Something was wrong, please contact your SysAdmin.')
                    }
                  })
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire(
                  "Cancelled",
                  "Agent is in a safe place :)",
                  "error"
              );
            }
          });
    },
    handleFocus(e){
      e.target.setAttribute("autocomplete", "nope");
    },
    viewUserGroup(request) {
      const currentRole = jwt_decode(localStorage.getItem('jwt'))
      return currentRole.userGroup.includes(request)
    },
    printEmit(notify) {
      this.chatMessagesData = notify;
    },
    formSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        const message = this.form.message;
        const currentDate = new Date();
        this.chatMessagesData.push({
          align: "right",
          name: "Henry Wells",
          message,
          time: currentDate.getHours() + ":" + currentDate.getMinutes(),
        });
      }
      this.submitted = false;
      this.form = {};
    },
    chatUsername(name, image) {
      this.username = name;
      this.profile = image;
      this.usermessage = "Hello";
      this.chatMessagesData = [];
      const currentDate = new Date();

      this.chatMessagesData.push({
        name: this.username,
        message: this.usermessage,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
      });
    },
    async getNotes (id = localStorage.getItem('selectedUser')) {
      const data = {
        token: localStorage.getItem('jwt'),
        userID: localStorage.getItem('id'),
        agentID: id,
        clientID: id,
      }

      localStorage.setItem('selectedUser', id)

      let url = '';
      url = '/api/getUserNotes';

      await axios
          .post(url, data)
          .then(response => {
            this.notesData = response.data;
          })
          .catch(error => {
            if (error.response) {
              Swal.fire("Oops!", "Notes could not be loaded, please contact your SysAdmin.", "error")
            }
          })
    },
    showPassword: function() {
      var x = document.getElementById("newpasswordInput");
      var y = document.getElementById("confirmpasswordInput");
      if (x.type === "password") {
        x.type = "text";
        y.type = "text";
      } else {
        x.type = "password";
        y.type = "password";
      }
    },
    async updateUserPassword(){

      this.errors = []
      if (!this.password) {
        this.errors.push('New password is required.');
      }

      if (!this.confirmPasword) {
        this.errors.push('Confirm Password is required.');
      }

      if((this.password !== this.confirmPasword) || (this.password.length <= 0 && this.confirmPasword.length <= 0) ){
        this.errors.push('Passwords don\'t match.');
      }

      if (!this.errors.length) {
        const data = {
          token: localStorage.getItem('jwt'),
          userID: localStorage.getItem('selectedUser'),
          password: this.password,
          confirmPassword: this.confirmPasword,
        }

        await axios
            .put('/api/updateUserPassword', data)
            .then(async response => {
              if (response.status === 200) {
                await Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Password updated!'
                });
                document.getElementById('closemodalPassword').click();
                this.password = '';
                this.confirmPasword = '';
              }
            })
            .catch(async error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  this.$router.push('logout')
                }

                let list = '';
                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                  list += '\n' + '<b>' + error.response.data[property] + '</b>';
                }

                await Swal.fire({
                  title: "Please check the following: " + list,
                  icon: 'warning',
                  confirmButtonClass: "btn btn-info",
                  buttonsStyling: false,
                  showCloseButton: true,
                });

              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      } else {
        let list = '';
        for (let i = 0; i < this.errors.length; i++)
          list += '\n' + '<b>' + this.errors[i] + '</b>';

        await Swal.fire({
          title: "Please complete the form! " + list,
          icon: 'warning',
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    setUserID(ID) {
      localStorage.setItem('selectedUser', ID)
    },
    getMapsLink(){
      let link = 'https://maps.google.com/?q='
      if (this.agentData.address.length > 0){
        link += '+'+this.agentData.address
      }
      if (this.agentData.zipCode.length > 0){
        link += '+'+this.agentData.zipCode
      }
      if (this.agentData.city.length > 0){
        link += '+'+this.agentData.city
      }
      if (this.agentData.state.length > 0){
        link += '+'+this.agentData.state
      }

      if(link !== 'https://maps.google.com/?q=') {
        document.getElementById("gMapsLink").href = link;
      } else {
        document.getElementById("gMapsLink").href = '#';
      }

    },
    async getUserPermissions() {

      const data = {
        token: localStorage.getItem('jwt'),
        userID: localStorage.getItem('selectedUser'),
        select: document.getElementById('givePermissions'),
      }

      await axios
          .post('/api/getPermissionsCount', data)
          .then(async response => {

            if (response.status === 200) {
              if (response.data === 0) {
                this.havePermissions = 2;
              } else {
                this.havePermissions = 1;
              }
            }

          })
          .catch(async error => {
            if (error.response) {

              if (error.response.data.detail === 'Your session is expired!') {
                this.$router.push('logout')
              }

              let list = '';
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
                list += '\n' + '<b>' + error.response.data[property] + '</b>';
              }

              await Swal.fire({
                title: "Please check the following: " + list,
                icon: 'warning',
                confirmButtonClass: "btn btn-info",
                buttonsStyling: false,
                showCloseButton: true,
              });

            } else if (error.message) {
              this.errors.push('Something was wrong, please contact your SysAdmin.')
            }
          })
    },
    async getNotificaitons(id=0) {

      const data = {
        token: localStorage.getItem('jwt'),
        userID: id
      }

      await axios
          .post('/api/getNotificaitons', data)
          .then(async response => {

            if (response.status === 200) {
              this.receiveNotifications = response.data ;
            }

          })
          .catch(async error => {
            if (error.response) {

              if (error.response.data.detail === 'Your session is expired!') {
                this.$router.push('logout')
              }

              let list = '';
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
                list += '\n' + '<b>' + error.response.data[property] + '</b>';
              }

              await Swal.fire({
                title: "Please check the following: " + list,
                icon: 'warning',
                confirmButtonClass: "btn btn-info",
                buttonsStyling: false,
                showCloseButton: true,
              });

            } else if (error.message) {
              this.errors.push('Something was wrong, please contact your SysAdmin.')
            }
          })
    },
    async permissionsToUser() {
      if (document.getElementById('givePermissions') !== '') {

        const data = {
          token: localStorage.getItem('jwt'),
          userID: localStorage.getItem('selectedUser'),
          select: document.getElementById('givePermissions'),
          permission: this.havePermissions,
        }

        await axios
            .post('/api/updatePermissions', data)
            .then(async response => {

              if (response.status === 200) {

                if (this.havePermissions === '1' && response.data === 1){
                  await Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Permissions assigned!'
                  });
                } else if(response.data === 0){
                  await Swal.fire({
                    icon: 'info',
                    title: 'No change applied.',
                    text: 'Nothing Happens!'
                  });
                } {
                  await Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Permissions removed!'
                  });
                }

                document.getElementById('closemodalPassword').click();
              }

            })
            .catch(async error => {
              if (error.response) {

                if (error.response.data.detail === 'Your session is expired!') {
                  this.$router.push('logout')
                }

                let list = '';
                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                  list += '\n' + '<b>' + error.response.data[property] + '</b>';
                }

                await Swal.fire({
                  title: "Please check the following: " + list,
                  icon: 'warning',
                  confirmButtonClass: "btn btn-info",
                  buttonsStyling: false,
                  showCloseButton: true,
                });

              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      }
    },
    handleFileUpload(){
      this.file = document.getElementById('listFile').files[0];
      this.parseFile();
    },
    parseFile(){
      var allowedExtensions = /(\.csv)$/i;
      if (allowedExtensions.exec(this.file.name)) {
        Papa.parse( this.file, {
          header: true,
          skipEmptyLines: true,
          complete: function ( results ) {
            if( this.compare(this.spectedColumnsindeed, results.meta.fields)){
              this.content = results;
              this.parsed = true;
            } else {
              Swal.fire({
                title: "Oops, this file is not what we spected!",
                icon: 'warning',
                confirmButtonClass: "btn btn-info",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }.bind(this)
        } );
      } else {
        Swal.fire({
          title: "File extension not supported!",
          icon: 'warning',
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    compare (o1, o2){
      for(let p in o1){
        // eslint-disable-next-line no-prototype-builtins
        if(o1.hasOwnProperty(p)){
          if(o1[p] !== o2[p]){
            return false;
          }
        }
      }
      for(let p in o2){
        // eslint-disable-next-line no-prototype-builtins
        if(o2.hasOwnProperty(p)){
          if(o1[p] !== o2[p]){
            return false;
          }
        }
      }
      return true;
    },
    async uploadFile(){

      this.errors = []
      var departmentSelected = 0;

      if (document.getElementById('uploadLanguage').value === '') {
        this.errors.push('Language is required.');
      }

      if (document.getElementById('uploadStatus').value === '') {
        this.errors.push('Status is required.');
      }

      if (document.getElementById('uploadType').value === '') {
        this.errors.push('Source is required.');
      }

      if (document.querySelector('#departmentType')) {
        if (document.getElementById('departmentType').value === '' && this.viewUserGroup('SUPER_ADMIN_GROUP')) {
          this.errors.push('Department is required.');
          departmentSelected = 0
        }
      } else {
        departmentSelected = document.getElementById('departmentType').value;
      }

      if (this.content.length === 0) {
        this.errors.push('Please, upload and process your .csv file.');
      }

      if(this.errors.length === 0){

        const data = {
          token: localStorage.getItem('jwt'),
          userEmail: localStorage.getItem('email'),
          userID: localStorage.getItem('id'),
          listOfData: this.content,
          uploadTo:  localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastname')
              + ' | ' +localStorage.getItem('email'),
          isProspect: 0,
          language: document.getElementById('uploadLanguage').value,
          status: document.getElementById('uploadStatus').value,
          source: document.getElementById('uploadType').value,
          department: departmentSelected,
          listType: 1,
        }

        await axios
            .post('/api/uploadAgentList', data)
            .then(async response => {
              if (response.status === 200) {
                await Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Uploaded successfully!'
                });
              }
              setTimeout(function() {
                location.reload()
              }, 2000);
            })
            .catch(async error => {
              if (error.response) {

                let list = '';
                for (const property in error.response.data) {
                  this.errors.push(`${property}: ${error.response.data[property]}`)
                  list += '\n' + '<b>' + error.response.data[property] + '</b>';
                }

                await Swal.fire({
                  title: "Please check the following: " + list,
                  icon: 'warning',
                  confirmButtonClass: "btn btn-info",
                  buttonsStyling: false,
                  showCloseButton: true,
                });

              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      }else{
        let list = '';
        for (let i = 0; i < this.errors.length; i++)
          list += '\n' + '<b>' + this.errors[i]+ '</b>';

        await Swal.fire({
          title: "Please complete the form! " + list,
          icon: 'warning',
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async updateNotifications(){

      this.errors = []
      let notifications = document.querySelector('#receivedNotification').checked;

      if (!this.errors.length) {
        const data = {
          token: localStorage.getItem('jwt'),
          userID: localStorage.getItem('selectedUser'),
          notifications: notifications
        }

        await axios
            .put('/api/updateNotifications', data)
            .then(async response => {
              if (response.status === 200) {
                await Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Notifications updated!'
                });
                this.password = '';
                this.confirmPasword = '';
              }
            })
            .catch(async error => {
              if (error.response) {

                if(error.response.data.detail === 'Your session is expired!'){
                  this.$router.push('logout')
                }

                await Swal.fire({
                  title: "Something went wrong... ",
                  icon: 'warning',
                  confirmButtonClass: "btn btn-info",
                  buttonsStyling: false,
                  showCloseButton: true,
                });

              } else if (error.message) {
                this.errors.push('Something was wrong, please contact your SysAdmin.')
              }
            })
      } else {
        let list = '';
        for (let i = 0; i < this.errors.length; i++)
          list += '\n' + '<b>' + this.errors[i] + '</b>';

        await Swal.fire({
          title: "Please complete the form! " + list,
          icon: 'warning',
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // true for mobile device
        this.mobileView = true;
      } else {
        // false for not mobile device
        this.mobileView = false;
      }
    },
  },
  async mounted() {

    this.isMobile();
    let field = 'n';
    let state = 'state';
    let url = window.location.href;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const status = urlParams.get(field)
    const selectedState = urlParams.get(state)

    if (selectedState != null){
      this.data.searchValues.statesList = selectedState;
    }

    const data = {
      token: localStorage.getItem('jwt'),
      searchValues: this.data.searchValues,
      isActiveState: 1
    }

    await axios
        .post('/api/listDepartments', data)
        .then(response => {
          this.departmentList=response.data
        })
        .catch(error => {
          if (error.response) {

            if(error.response.data.detail === 'Your session is expired!'){
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Departments could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    await axios
        .post('/api/listStatus', data)
        .then(response => {
          this.statusList=response.data
        })
        .catch(error => {
          if (error.response) {

            if(error.response.data.detail === 'Your session is expired!'){
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Status could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    await axios
        .post('/api/listSource', data)
        .then(response => {
          this.sourcesList = response.data
        })
        .catch(error => {
          if (error.response) {

            if(error.response.data.detail === 'Your session is expired!'){
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Status could not be loaded, please contact your SysAdmin.", "error")
          }
        })

    await axios
        .post('/api/listStates', data)
        .then(response => {
          this.statesList = response.data
        })
        .catch(error => {
          if (error.response) {

            if(error.response.data.detail === 'Your session is expired!'){
              this.$router.push('logout')
            }

            Swal.fire("Oops!", "Status could not be loaded, please contact your SysAdmin.", "error")
          }
        })


    if((url.indexOf('?' + field + '=') !== -1))
      document.getElementById(status).click()
    else
      await axios
          .post('/api/listAgents', data)
          .then(response => {
            this.list=response.data;
            this.listQty = this.list.length
            this.page = 1
            this.setPages();
          })
          .catch(error => {
            if (error.response) {

              if(error.response.data.detail === 'Your session is expired!'){
                this.$router.push('logout')
              }

              // for (const property in error.response.data) {
              // this.errors.push(`${property}: ${error.response.data[property]}`)
              // }
            } else if (error.message) {
              this.errors.push('Something was wrong, please contact your SysAdmin.')
            }
          })

    const checkAll = document.getElementById("checkAll");

    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
            '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.list);
    },
    resultQuery() {
      if (this.data.searchValues.keyword.length >= 3) {
        const search = this.data.searchValues.keyword.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
              data.address.toLowerCase().includes(search) ||
              data.agentNumber.toLowerCase().includes(search) ||
              data.licenseNumber.toLowerCase().includes(search) ||
              data.email.toLowerCase().includes(search) ||
               data.phoneNumber.toLowerCase().includes(search) ||
              data.firstName.toLowerCase().includes(search) ||
              data.lastName.toLowerCase().includes(search) ||
              data.fullname.toLowerCase().includes(search) ||
              data.statusName.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
};
</script>
<style>
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  position: absolute;
  bottom: -1.6em;
  left: 100%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f8f8f8),color-stop(1, #cccccc));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);
  background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <loader v-if="isLoading" :isLoading="isLoading" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="leadsList">


          <div class="card-header">
            <div class="d-flex align-items-center flex-wrap gap-2">
              <div class="flex-grow-1">
                <div class="row">
                  <div class="col-xxl-4 col-sm-4" style="margin-bottom: 5px;">
                    <div class="search-box">
                      <input
                          type="text"
                          class="form-control search"
                          placeholder="Search for..."
                          title="Search by (Phone number, City, Postal code, Name, Last name, Full Name, Address, State)"
                          v-model="this.data.searchValues.keyword"
                          @change="this.data.searchValues.keyword.length >= 3 ? updateAgentList(): ''"
                          v-on:keyup.enter="this.data.searchValues.keyword === '' ? updateAgentList() : ''"
                      />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>

                  <div class="col-xxl-4 col-sm-4" style="margin-bottom: 5px;">
                    <div class="d-flex align-items-center gap-2">
                      <span class="text-muted flex-shrink-0">Sort by: </span>

                      <Multiselect class="form-control"
                                   style="padding: 0px;"
                                   autocomplete="off"
                                   @focus="handleFocus"
                                   v-model="this.data.searchValues.sortBy"
                                   :close-on-select="true"
                                   :searchable="true"
                                   :options="[
                            { value: 'name', label: 'Name' },
                            { value: 'lastName', label: 'Last Name' },
                            { value: 'postalCode', label: 'Postal Code' },
                            { value: 'modified', label: 'Modified On' },
                            { value: 'created', label: 'created On' },
                            ]"
                                   @select="updateAgentList()"
                      />
                    </div>
                  </div>

                  <div class="col-xxl-4 col-sm-4" style="margin-bottom: 5px;">
                    <div class="d-flex align-items-center gap-2">
                      <span class="text-muted flex-shrink-0">Order by: </span>

                      <Multiselect class="form-control"
                                   style="padding: 0px;"
                                   v-model="this.data.searchValues.orderBy"
                                   autocomplete="off"
                                   @focus="handleFocus"
                                   :close-on-select="true"
                                   :searchable="true"
                                   :options="[
                            { value: 'ASC', label: 'Ascendance' },
                            { value: 'DESC', label: 'Descendant' },
                            ]"
                                   @select="updateAgentList()"
                      />

                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-shrink-0">
                <div class="hstack text-nowrap gap-2">
                  <button type="button" class="btn btn-soft-success float-end" href="#open-filters" data-bs-toggle="modal">
                    <i class="ri-filter-line align-bottom me-1"></i> Filters
                  </button>
                  <button type="button" class="btn btn-soft-success float-end" id="create-btn" data-bs-toggle="modal" href="#agentModal" @click="addNewAgentModal" style="white-space: nowrap;">
                    <i class="ri-add-line align-bottom me-1"></i> Add Agent
                  </button>
                  <button
                      type="button"
                      id="dropdownMenuLink1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      class="btn btn-soft-info"
                  >
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                    <li><a class="dropdown-item" href="#interiewCalendar" data-bs-toggle="modal">Open Calendar</a></li>
                    <li><a class="dropdown-item" href="#uploadAgents" data-bs-toggle="modal">Upload Agents</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Table Head -->
          <ul
              class="nav nav-tabs nav-tabs-custom nav-primary mb-3"
              role="tablist">
            <li class="nav-item">
              <a
                  class="nav-link active All py-3"
                  data-bs-toggle="tab"
                  id="All"
                  href="#home1"
                  role="tab"
                  aria-selected="true"
                  @click="updateAgentList(0)"
              >
                <i class="ri-file-search-line me-1 align-bottom"></i> All
              </a>
            </li>
             <li class="nav-item" v-for="(data, index) of statusList" :key="index">
              <a
                  class="nav-link py-3 "
                  v-bind:class="data.name"
                  data-bs-toggle="tab"
                  v-bind:id="data.name"
                  v-bind:href="'#'+data.name"
                  role="tab"
                  aria-selected="false"
                  @click="updateAgentList(data.statusID)"
              >
                <i class="me-1 align-bottom"></i>
                {{ data.name }}
              </a>
            </li>
          </ul>

          <div class="card-body">
            <span v-if="mobileView === false">
              <div class="table-responsive table-card mb-4">
                <table class="table align-middle table-nowrap mb-0 table-hover">
              <thead class="table-light">
              <tr>
                <th scope="col" style="width: 50px">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        value="option"
                    />
                  </div>
                </th>
                <th scope="col">Name</th>
                <th scope="col">Department</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col" v-if="this.data.searchValues.status === 2">Interview Date</th>
                <th scope="col">Postal Code</th>
                <th scope="col">Create Date</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data, index) of resultQuery" :key="index">
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="cardtableCheck01">
                    <label class="form-check-label" for="cardtableCheck01"></label>
                  </div>
                </td>
                <td>{{ data.firstName }} {{ data.lastName }}</td>
                <td>{{ data.departmentName }}</td>
                <td>{{ data.email }}</td>
                <td>{{ formatPhone(data.phoneNumber) }}</td>
                <td v-if="this.data.searchValues.status === 2">{{ formatDate(data.interviewDate, 'MMMM Do YYYY, h:mmA')  }}</td>
                <td>{{ data.postalCode }}</td>
                <td> {{ formatDate(data.createdOn, 'MMMM Do YYYY, h:mmA') }} </td>
                <td><a href="javascript:void(0);" v-bind:style="'color:'+data.color" @click="filterStatus(data.statusName)">{{data.statusName}}</a></td>
                <td>
                  <ul class="list-inline hstack gap-2 mb-0">
                    <li class="list-inline-item" data-bs-toggle="modal" href="#agentModal" data-bs-trigger="hover" data-bs-placement="top" data-title="Edit" @click="getUserInfo(data.ID)">
                      <a class="edit-item-btn" href="javascript:void(0);" style="color: #25a0e2"><i class="ri-pencil-fill align-bottom"></i></a>
                    </li>
                    <li class="list-inline-item" data-bs-toggle="modal" href="#open-notes" data-bs-trigger="hover" data-bs-placement="top" data-title="Notes" @click="getNotes(data.ID)">
                      <a href="javascript:void(0);" class="d-inline-block" style="color: #FFBC0A">
                        <i class="ri-sticky-note-line fs-16"></i>
                      </a>
                    </li>
                    <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" data-title="Delete" @click="deleteUser(data.ID)">
                      <a class="remove-item-btn" data-bs-toggle="modal" href="javascript:void(0);" style="color: #e30000">
                        <i class="ri-delete-bin-fill align-bottom"></i>
                      </a>
                    </li>
                    <li class="list-inline-item" data-bs-toggle="modal" href="#open-changePassword" data-bs-trigger="hover" data-bs-placement="top" data-title="Change Password" @click="setUserID(data.ID), getUserPermissions(), getNotificaitons(data.ID)" v-if="viewUserGroup('SUPER_ADMIN_GROUP')">
                      <a class="edit-item-btn" href="javascript:void(0);" style="color: #2ca219"><i class="ri-key-fill align-bottom"></i></a>
                    </li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
                <!--end table-->
            </div>
            </span>
            <span v-else>
            <div class="table-responsive">
              <table class=" table table-hover align-middle  mb-0 table-nowrap">
                <thead class="table-light">
                <tr>
                  <th scope="col" class="text-center">CARDS</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) of resultQuery" :key="index">
                  <td>
                    <div class="collapse show" :id="'leadDiscovered1'+data.ID">
                        <div class="card mb-1">
                            <div class="card-body" style=" padding: 10px 10px 10px 5px !important;">
                                <a class="d-flex align-items-center" data-bs-toggle="collapse"
                                   :href="'#leadDiscovered'+data.ID" role="button" aria-expanded="false"
                                   :aria-controls="'leadDiscovered'+data.ID">
                                    <div class="flex-shrink-0">
                                        <img src="@/assets/images/logo-circle-white.png" alt="" class="avatar-xs rounded-circle"/>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="fs-14 mb-1">{{ data.firstName }} {{ data.lastName }}</h6>
                                        <p class="text-muted mb-0" v-if="agentVisible">{{
                                            data.departmentName
                                          }}
                                        </p>
                                        <p class="text-muted mb-0">{{
                                            data.statusName
                                          }}
                                        </p>
                                        <p class="text-muted mb-0" v-if="this.data.searchValues.status === 2">{{
                                            formatDate(data.interviewDate, 'MMMM Do YYYY, h:mmA')
                                          }}
                                        </p>
                                        <p class="text-muted mb-0">{{
                                            data.email.substring(0, 30)
                                          }}
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div class="collapse border-top border-top-dashed" :id="'leadDiscovered'+data.ID">
                                <div class="card-body">
                                    <h6 class="fs-14 mb-1"><small class="badge badge-soft-primary">{{ data.labelName }}</small></h6>
                                    <ul class="list-unstyled vstack gap-2 mb-0">
                                        <li>
                                            <div class="d-flex">
                                                <div class="flex-shrink-0 avatar-xxs text-muted">
                                                    <i class="ri-phone-fill"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="mb-0">Phone Number</h6>
                                                    <small class="text-muted"> {{ data.phoneNumber }}</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex">
                                                <div class="flex-shrink-0 avatar-xxs text-muted">
                                                    <i class="ri-mail-fill"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="mb-0">Email</h6>
                                                    <small class="text-muted">{{ data.email }}</small>
                                                </div>
                                            </div>
                                        </li>
                                      <li>
                                            <div class="d-flex">
                                                <div class="flex-shrink-0 avatar-xxs text-muted">
                                                    <i class="ri-calendar-2-fill"></i>
                                                </div>
                                                <div class="flex-grow-s1">
                                                    <h6 class="mb-0">Created On</h6>
                                                    <small class="text-muted">{{ formatDate(data.createdOn, 'MMMM Do YYYY, h:mmA') }}</small>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-footer hstack gap-2">
                                  <div class="row">
                                    <div class="col-md-6" style="margin-bottom: 5px;">
                                      <button class="btn btn-primary btn-sm w-100 edit-item-btn"  data-bs-toggle="modal"
                                              href="#agentModal" data-bs-trigger="hover" data-bs-placement="top" title="Edit"
                                              @click="getUserInfo(data.ID)">
                                        <i class="ri-pencil-fill align-bottom me-1"></i> Edit
                                      </button>
                                    </div>
                                    <div class="col-md-6" style="margin-bottom: 5px;">
                                      <button class="btn btn-primary btn-sm w-100"
                                              data-bs-trigger="hover" data-bs-placement="top" title="Call">
                                          <i class="ri-phone-line align-bottom me-1"></i>
                                          <a :href="'tel:'+data.phone" style="color: white"> Make Call </a>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-6" style="margin-bottom: 5px;">
                                      <button class="btn btn-warning btn-sm w-100"
                                              data-bs-toggle="modal" href="#open-notes" data-bs-trigger="hover"
                                              data-bs-placement="top" title="Notes" @click="getNotes(data.ID)">
                                          <i class="ri-refresh-fill align-bottom me-1"></i> Notes
                                      </button>
                                    </div>
                                    <div class="col-md-6" style="margin-bottom: 5px;">
                                      <button class="btn btn-danger btn-sm w-100"
                                              data-bs-toggle="modal" href="#open-changePassword" data-bs-trigger="hover" data-bs-placement="top" title="Change Password"
                                              @click="setUserID(data.ID), getUserPermissions(), getNotificaitons(data.ID)" v-if="viewUserGroup('SUPER_ADMIN_GROUP')">
                                           <i class="ri-key-fill align-bottom"></i>
                                            Password
                                      </button>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div><!--end card-->
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            </span>
            <div class="noresult" v-if="list.length <= 0">
              <div class="text-center">
                <lottie
                    class="avatar-xl"
                    colors="primary:#121331,secondary:#08a88a"
                    :options="defaultOptions"
                    :height="75"
                    :width="75"
                />
                <h5 class="mt-2">Sorry!</h5>
                <p class="text-muted mb-0">
                  We've searched and we did not find any
                  agent for your search.
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-3" v-if="resultQuery.length >= 0">
              <div class="pagination-wrap hstack gap-2">
                <a
                    class="page-item pagination-prev disabled"
                    href="#"
                    v-if="page !== 1"
                    @click="page--"
                >
                  Previous
                </a>
                <ul class="pagination listjs-pagination mb-0">
                  <li
                      :class="{
                      active: pageNumber == page,
                      disabled: pageNumber == '...',
                    }"
                      v-for="(pageNumber, index) in pages.slice(
                      page - 1,
                      page + 5
                    )"
                      :key="index"
                      @click="page = pageNumber"
                  >
                    <a class="page" href="#">{{ pageNumber }}</a>
                  </li>
                </ul>
                <a
                    class="page-item pagination-next"
                    href="#"
                    @click="page++"
                    v-if="page < pages.length"
                >
                  Next
                </a>
                <span>
                  Agents Found: <b>{{ this.listQty }}</b>
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <!--  Add agent modal  -->
    <div class="modal zoomIn" id="agentModal" tabindex="-1" aria-labelledby="agentModalLabel" aria-hidden="true" data-bs-focus="false" style="z-index: 995;">
      <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width: 1000px;">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="agentModalLabel"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    id="close-modal"></button>
          </div>
          <form id="addform" @submit.prevent="">
            <div class="modal-body">
              <h6> <span style="color:red; font-size: 0.7em;">Required fields (*)</span></h6>
              <input type="hidden" id="id"/>
              <div class="row g-3">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-6">
                      <label for="editEmail" class="form-label">Email <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <input type="text" id="editEmail" class="form-control mb-2" placeholder="Enter Email" v-model="agentData.email" required>
                    </div>
                    <div class="col-lg-6">
                      <label for="editCreatedBy" class="form-label">Created By</label>
                      <input type="text" id="editCreatedBy" class="form-control mb-2" disabled v-model="this.agentData.createdBy">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label for="editFirstName" class="form-label">First Name <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <input type="text" id="editFirstName" class="form-control mb-2" placeholder="Enter First Name" v-model="this.agentData.firstName" required>
                    </div>
                    <div class="col-lg-6">
                      <label for="editLastName" class="form-label">Last Name <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <input type="text" id="editLastName" class="form-control mb-2" placeholder="Enter Last Name" v-model="this.agentData.lastName" required>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label for="editLanguage" class="form-label">Language <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <select class="form-select mb-2" aria-label="Language" id="editLanguage" v-model="this.agentData.language" required>
                        <option value="1" selected>English</option>
                        <option value="2">Spanish</option>
                      </select>
                    </div>
                    <div class="col-lg-3">
                      <label for="editStatus" class="form-label">Status <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <select class="form-select mb-2" aria-label="Status" id="editStatus" v-model="this.agentData.status">
                        <option v-bind:style="(!viewUserGroup('SUPER_ADMIN_GROUP') && status.statusID === 9)? 'display: none;' : ''" v-for="(status, statusIndex) of statusList" :key="statusIndex" v-bind:value="status.statusID">{{ status.name }}</option>
                      </select>
                    </div>
                    <div class="col-lg-3">
                      <label for="editSource" class="form-label">Source</label>
                      <select class="form-select mb-2" aria-label="Source" id="editSource" v-model="this.agentData.source">
                        <option v-for="(source, sourceIndex) of sourcesList" :key="sourceIndex" v-bind:value="source.sourceID">{{ source.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label for="editPhoneNumber" class="form-label">Phone <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <input type="text" id="editPhoneNumber" class="form-control mb-2" placeholder="Phone number" required v-model="this.agentData.phone"/>
                    </div>
                    <div class="col-lg-6">
                      <label for="editDepartment" class="form-label">Department <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <select class="form-select mb-2" aria-label="Department" id="editDepartment" name="editDepartment"
                              v-model="this.agentData.department" v-if="(departmentList.length > 1)">
                        <option v-for="(department, index) of departmentList"  :key="index"
                                v-bind:value="department.departmentID">{{ department.name }}
                        </option>
                      </select>
                      <select class="form-select mb-2" aria-label="Department" id="editDepartment" name="editDepartment"
                              v-model="this.agentData.department" v-else>
                        <option v-bind:value="departmentList.departmentID">{{ departmentList.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label for="editAddress" class="form-label">Address <span style="color:red; font-size: 0.7em;">(*)</span></label>&nbsp;<code><a href="#" target="_blank" id="gMapsLink">Google Maps</a></code>
                      <input type="text" id="editAddress" class="form-control mb-2" placeholder="Enter Address" required v-model="this.agentData.address"/>
                    </div>
                    <div class="col-lg-6">
                      <label for="editBirthDate" class="form-label mb-2">Birth Date <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <flat-pickr
                          placeholder="Select Birth Date"
                          v-model="this.agentData.birthDate"
                          :config="timeConfig"
                          class="form-control flatpickr-input"
                          id="editBirthDate"></flat-pickr>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3">
                      <label for="editCity" class="form-label">City</label>
                      <input type="text" id="editCity" class="form-control mb-2" placeholder="Enter City" required v-model="this.agentData.city"/>
                    </div>
                    <div class="col-lg-3">
                      <label for="editState" class="form-label">State <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <select class="form-select mb-2" aria-label="Source" id="editState" v-model="this.agentData.state" required>
                        <option v-for="(source, sourceIndex) of statesList" :key="sourceIndex" v-bind:value="source.value">{{ source.label }}</option>
                      </select>
                    </div>
                    <div class="col-lg-6">
                      <label for="editCountry" class="form-label">Country</label>
                      <input type="text" id="editCountry" class="form-control mb-2" placeholder="Enter Country" required v-model="this.agentData.country"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label for="editPostalCode" class="form-label">Postal code</label>
                      <input type="text" id="editPostalCode" class="form-control mb-2" placeholder="Enter Address" required v-model="this.agentData.zipCode"/>
                    </div>
                    <div class="col-lg-6">
                      <label for="editInterview" class="form-label mb-2">Interview</label>
                      <flat-pickr
                          placeholder="Select Interview date/time"
                          v-model="this.agentData.interview"
                          :config="dateTimeConfig"
                          class="form-control flatpickr-input"
                          id="editInterview" data-enable-time></flat-pickr>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <label for="editLicensed" class="form-label">Is Licensed? <span style="color:red; font-size: 0.7em;">(*)</span></label>
                      <select class="form-select mb-2" aria-label="Department" id="editLicensed" v-model="this.agentData.insuranceLicense">
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <label for="editAgentNumber" class="form-label">License Number</label>
                      <input type="text" id="editLicenseNumber" class="form-control mb-2" placeholder="Enter Agent Number" v-model="this.agentData.licenseNumber"/>
                    </div>
                    <div class="col-lg-4">
                      <label for="editAgentNumber" class="form-label">Agent Number</label>
                      <input type="text" id="editAgentNumber" class="form-control mb-2" placeholder="Enter Agent Number" v-model="this.agentData.agentNumber"/>
                    </div>
                  </div>
                  <div class="row">

                  </div>
                  <div class="row" id="editNoteOpen" style="display: none;">
                    <notes :dataList="notesData" @onFire="getNotes()" :idNumber="1"> </notes>
                  </div>
                  <div class="row" id="editNotes">
                    <div class="mt-4">
                      <label
                          for="editNotes"
                          class="form-label text-muted text-uppercase fw-semibold"
                      >Notes</label>
                      <textarea
                          class="form-control alert alert-primary"
                          v-model="this.agentData.note"
                          name="editNotes"
                          placeholder="Notes"
                          rows="2"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>

            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" id="closemodal" data-bs-dismiss="modal">Close </button>
                <button type="submit" class="btn btn-success" id="add-btn" @click="createAgent">Save </button>
                <button type="button" class="btn btn-success" id="edit-btn" @click="updateAgent">Update </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Filters Modal -->
    <div id="open-filters" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel">Filters</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <label for="filterKeyword" class="form-label">Keyword</label>
                <input type="text" id="filterKeyword" name="filterKeyword" class="form-control mb-2" placeholder="Enter Keyword" v-model="this.data.searchValues.keyword">
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterLicensed" class="form-label">Licensed agents?</label>
                <select class="form-select mb-2" aria-label="Licensed Agents?" id="filterLicensed" name="filterLicensed" v-model="this.data.searchValues.licensed">
                  <option value="1" selected>Both</option>
                  <option value="2">Yes</option>
                  <option value="3">No</option>
                </select>
              </div>
              <div class="col-lg-6">
                  <label for="editStatus" class="form-label">Status</label>
                  <select class="form-select mb-2" aria-label="Status" id="filterStatus" v-model="this.data.searchValues.status">
                    <option value="0">All</option>
                    <option v-for="(status, statusIndex) of statusList" :key="statusIndex" v-bind:value="status.statusID">{{ status.name }}</option>
                  </select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="editLanguage" class="form-label">Language</label>
                <select class="form-select mb-2" aria-label="Language" id="filterLanguage" name="filterLanguage" v-model="this.data.searchValues.language">
                  <option value="3" selected>Both</option>
                  <option value="1">English</option>
                  <option value="2">Spanish</option>
                </select>
              </div>
              <div class="col-lg-6">
                <label for="editStatus" class="form-label">Search by Notes</label>
                <input type="text" id="filterNotes" name="filterNotes" class="form-control mb-2" placeholder="Enter note" v-model="this.data.searchValues.noteSearch">
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterFromDate" class="form-label"> From date </label>
                <flat-pickr
                    v-model="this.data.searchValues.fromDate"
                    :config="humanfriendlyConfig"
                    class="form-control flatpickr-input"
                    id="filterFromDate"
                    name="filterFromDate"
                ></flat-pickr>
              </div>
              <div class="col-lg-6">
                <label for="filterToDate" class="form-label"> To Date </label>
                <flat-pickr
                    v-model="this.data.searchValues.toDate"
                    :config="humanfriendlyConfig"
                    class="form-control flatpickr-input"
                    id="filterToDate"
                    name="filterToDate"
                ></flat-pickr>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <label for="filterType" class="form-label">Source</label>
                <select class="form-select mb-2" aria-label="Status" id="filterType" name="filterType" v-model="this.data.searchValues.source">
                  <option value="0">All</option>
                  <option v-for="(source, typeIndex) of sourcesList" :key="typeIndex" v-bind:value="source.sourceID">{{ source.name }}</option>
                </select>
              </div>
              <div class="col-lg-6">
                <label for="filterState" class="form-label">State</label>
                <select class="form-select mb-2" aria-label="Source" id="filterState" v-model="this.data.searchValues.statesList" required>
                  <option v-for="(source, sourceIndex) of statesList" :key="sourceIndex" v-bind:value="source.value">{{ source.label }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                  <label for="filterDateRange" class="form-label">Time Range</label>
                <select class="form-select mb-2" id="filterDateRange" name="filterDateRange" v-model="this.data.searchValues.dateRange">
                  <option value="0" selected>...</option>
                  <option value="1">Until 2 year Ago</option>
                  <option value="2">Until 4 year Ago</option>
                  <option value="3">Until 5 year Ago</option>
                  <option value="4">Begin of times</option>
                </select>
              </div>
              <div class="col-lg-6">
                <span v-if="viewUserGroup('SUPER_ADMIN_GROUP')">
                  <label for="filterDateRange" class="form-label">Department</label>
                    <select class="form-select mb-2" aria-label="Department" id="filterDepartment" name="filterDepartment"
                            v-model="this.data.searchValues.department">
                        <option value="0"> All</option>
                        <option v-for="(department, index) of departmentList"  :key="index"
                                v-bind:value="department.departmentID"> {{ department.name }}
                        </option>
                    </select>
                </span>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-check form-switch form-switch-lg" dir="ltr" style="margin-left: 3px;">
                  <input type="checkbox" class="form-check-input" id="isActive" checked="">
                  <label class="form-check-label" for="isActive">Active</label>
                </div>
              </div>
              <div class="col-lg-6">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="updateAgentList()">Search</button>
          </div>

        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <!-- Notes Modal -->
    <div id="open-notes" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <notes :dataList="notesData" @onFire="getNotes()" :idNumber="2"> </notes>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

    <!-- Change Password Modal -->
    <div class="modal zoomIn" id="open-changePassword" tabindex="-1" aria-labelledby="agentModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width: 1000px;">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="agentModalLabel"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    id="close-modal"></button>
          </div>
          <form id="addform" @submit.prevent="">
            <div class="modal-body">
              <div class="row g-2">
                <!--end col-->
                <div class="col-lg-6">
                  <div>
                    <label for="newpasswordInput" class="form-label"
                    >New Password*</label
                    >
                    <div class="position-relative auth-pass-inputgroup mb-3">
                      <input v-model="password"
                             type="password"
                             class="form-control pe-5 password-input"
                             placeholder="Enter new password"
                             id="newpasswordInput"
                             autocomplete="off">
                      <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" @click="showPassword()"><i class="ri-eye-fill align-middle"></i></button>
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-lg-6">
                  <div>
                    <label for="confirmpasswordInput" class="form-label"
                    >Confirm Password*</label
                    >
                    <div class="position-relative auth-pass-inputgroup mb-3">
                      <input v-model="confirmPasword"
                             type="password"
                             class="form-control pe-5 password-input"
                             placeholder="Confirm password"
                             id="confirmpasswordInput"
                             autocomplete="off">
                      <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" @click="showPassword()"><i class="ri-eye-fill align-middle"></i></button>
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <div class="row">
                  <div class="col-lg-6">
                    <label for="givePermissions" class="form-label">Give Permissions</label>
                    <select class="form-select mb-2" aria-label="givePermissions" id="givePermissions" name="givePermissions" @change="permissionsToUser()" v-model="this.havePermissions">
                      <option value="">...</option>
                      <option value="1">Yes</option>
                      <option value="2">No</option>
                    </select>
                  </div>
                <div class="col-lg-6">
                  <label for="receivedNotification" class="form-label"></label>
                  <br>
                  <div class="form-check form-switch form-switch-md form-switch-success">
                    <input class="form-check-input" type="checkbox" role="switch" id="receivedNotification" :checked="this.receiveNotifications" @click="updateNotifications">
                    <label class="form-check-label " for="receivedNotification">Receive Notifications?</label>
                  </div>
                </div>
              </div>

              <!--end row-->
            </div>

            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" id="closemodalPassword" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-success" id="edit-btn" @click="updateUserPassword">Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Upload agents calendar Modal -->
    <b-modal id="interiewCalendar" title="Fullscreen | Agents Appoinment Calendar" class="v-modal-custom" fullscreen>
      <div class="row">
        <calendar></calendar>
      </div>
    </b-modal>

    <b-modal id="uploadAgents" title="Fullscreen | Upload Agents" class="v-modal-custom" fullscreen>
      <div class="row">
        <div class="col-lg-12">
          <form>
            <!-- end card -->
            <div class="card">
              <div class="card-body">
                <div class="vstack gap-2">
                  <h5 class="fs-14 mb-1">Upload Your List</h5>
                  <h6> <span style="color:red; font-size: 0.7em;">Please, upload a .csv file from Lincoln or convert the .xls/.xlsx to .csv</span></h6>
                  <h6>
                    <a href="http://dmbgroup.online/wp-content/uploads/2024/08/Plantilla-para-subir-agentes.csv">
                      <span style="color:darkgreen; font-size: 0.9em;">Download .csv File</span>
                    </a>
                  </h6>
                  <input class="form-control" id="listFile" type="file"
                         accept="text/csv" @change="selectedFile"/>
                  <div class="row">
                    <div class="col-lg-3">
                      <label for="editLanguage" class="form-label">Language</label>
                      <select class="form-select mb-2" aria-label="Language" id="uploadLanguage" name="uploadLanguage">
                        <option value="">...</option>
                        <option value="1">English</option>
                        <option value="2">Spanish</option>
                        <option value="3">Take from list</option>
                      </select>
                    </div>
                    <div class="col-lg-3">
                      <label for="editStatus" class="form-label">Status</label>
                      <select class="form-select mb-2" aria-label="Status" id="uploadStatus" name="uploadStatus">
                        <option value="">...</option>
                        <option v-for="(status, statusIndex) of statusList" :key="statusIndex" v-bind:value="status.statusID">{{ status.name }}</option>
                      </select>
                    </div>
                    <div class="col-lg-3">
                      <label for="editType" class="form-label">Source</label>
                      <select class="form-select mb-2" aria-label="Status" id="uploadType" name="uploadType">
                        <option value="">...</option>
                        <option v-for="(type, typeIndex) of sourcesList" :key="typeIndex" v-bind:value="type.sourceID">{{ type.name }}</option>
                      </select>
                    </div>
                    <div class="col-lg-3" v-if="viewUserGroup('SUPER_ADMIN_GROUP')">
                      <label for="departmentType" class="form-label">Departments</label>
                      <select class="form-select mb-2" aria-label="Status" id="departmentType" name="departmentType" v-model="this.data.selectedDepartmentForAgentList">
                        <option value="0">...</option>
                        <option v-for="(type, typeIndex) of departmentList" :key="typeIndex" v-bind:value="type.departmentID">{{ type.name }}</option>
                      </select>
                    </div>
                    <div class="col-lg-3" v-if="!viewUserGroup('SUPER_ADMIN_GROUP')">
                      <label for="departmentType" class="form-label">Department</label>
                      <select class="form-select mb-2" aria-label="Status" id="departmentType" name="departmentType" v-model="this.data.selectedDepartmentForAgentList" disabled>
                        <option value="0">...</option>
                      </select>
                    </div>
                  </div>
                  <div class="border rounded" v-for="(file, index) of files" :key="index">
                    <div class="d-flex align-items-center p-2">
                      <div class="flex-grow-1">
                        <div class="pt-1">
                          <h5 class="fs-14 mb-1" data-dz-name="">
                            {{ file.name }}
                          </h5>
                          <p class="fs-13 text-muted mb-0" data-dz-size="">
                            <strong>{{ file.size / 1024 }}</strong> KB
                          </p>
                          <strong class="error text-danger" data-dz-errormessage=""></strong>
                        </div>
                      </div>
                      <div class="flex-shrink-0 ms-3">
                        <button data-dz-remove="" class="btn btn-sm btn-danger" @click="deleteRecord">
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="text-end mb-3">
                    <button type="button" class="btn btn-success w-sm" @click="handleFileUpload">Process File</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->
          </form>
        </div>
        <!-- end col -->
        <div class="card">
          <div class="card-body">

            <!-- Tables Border Colors -->
            <table class="table table-bordered border-secondary table-nowrap" v-if="parsed">
              <thead>
              <tr>
                <th scope="col">
                  ID
                </th>
                <th v-for="(header, key) in content.meta.fields"
                    v-bind:key="'header-'+key" scope="col">{{ header }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, rowKey) in content.data"
                  v-bind:key="'row-'+rowKey">
                <td>
                  <b>{{ (rowKey+1) }}</b>
                </td>
                <td v-for="(column, columnKey) in content.meta.fields"
                    v-bind:key="'row-'+rowKey+'-column-'+columnKey">
                  {{ content.data[rowKey][column] }}
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
        <div class="text-end mb-3">
          <button type="button" class="btn btn-success w-sm" @click="uploadFile" id="uploadAgentsButton">Upload File</button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
<style>
.row-spacer{
  margin-bottom: 10px;
}

button.swal2-cancel.btn.btn-success{
  margin-left: 5px;
}
</style>