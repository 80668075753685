<template>
  <div v-if="isLoading" class="loader-container" >
    <div class="loader">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props:['isLoading'],
  data() {
  },
  methods: {
    // showLoader() {
    //   this.isLoading = true;
    // },
    // hideLoader() {
    //   this.isLoading = false;
    // },
  },
};
</script>

<style scoped>
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Set a higher z-index than modals */
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #f3f3f3;
  border-color: #3498db; /* Change color as desired */
  animation: bounce 1.2s infinite ease-in-out;
}

/* Customize the animation for bouncing dots */
@keyframes bounce {
  0%, 100% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(0.8);
  }
}

.loader-text {
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
}

.bounce1, .bounce2, .bounce3 {
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  animation: bouncedelay 1.9s infinite ease-in-out;
  -webkit-animation-delay: -0.4s; /* Safari */
  animation-delay: -0.4s;
}

.bounce2 {
  -webkit-animation-delay: -0.8s; /* Safari */
  animation-delay: -0.8s;
}

@keyframes bouncedelay {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
</style>
