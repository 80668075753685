<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";


export default {
  page: {
    title: "Recruitment Training",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Recruitment Training",
      items: [
        {
          text: "Training",
          href: "/",
        },
        {
          text: "Recruitment",
          active: true,
        },
      ],
      pdfURL: 'https://dmbgroup.online/wp-content/uploads/2024/04/Blue-and-White-Geometric-Company-Profile-Presentation.pdf#toolbar=0'
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
        <h5 class="mb-0 pb-1 text-decoration-underline" style="text-decoration: none !important;">Training Introduction</h5>
      </div>
      <div class="col-xl-12">
        <object width="100%" height="500" type="application/pdf" :data="pdfURL">
          <p>PDF cannot be displayed.</p>
        </object>
      </div>
    </div>
    <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
      <h5 class="mb-0 pb-1 text-decoration-underline">Training Videos</h5>
    </div>

    <div class="row">
      <div class="col-xl-4" style="margin-bottom: 100px;">
        <!-- Ratio Video 16:9 -->
        <div class="ratio ratio-16x9">
          <div id="training-recruiters">

            <div class="card h-200">
              <div class="card-header">
                <h4 class="card-title mb-0">Enrique Amezquia - Entrenamiento de Reclutamiento</h4>
              </div>
              <div class="card-body">
                <iframe src="https://player.vimeo.com/video/914204453?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style="top:0;left:0;width:100%;height:100%; border-radius: 10px;"
                        title="02-16-2024 Reclutamiento">
                </iframe>
              </div>
              <div class="card-footer">
                <p class="card-text">
                  <small class="text-muted">Last updated 30 days ago</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4" style="margin-bottom: 100px;">
        <div class="ratio ratio-16x9">
          <div id="training-recruiters">

            <div class="card h-200">
              <div class="card-header">
                <h4 class="card-title mb-0">Karla Luna - Introducción</h4>
              </div>
              <div class="card-body">
                <iframe src="https://player.vimeo.com/video/948883556?h=ff06af169c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style="top:0;left:0;width:100%;height:100%; border-radius: 10px;"
                        title="02-16-2024 Reclutasmiento">
                </iframe>
              </div>
              <div class="card-footer">
                <p class="card-text">
                  <small class="text-muted">Last updated 30 days ago</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4"  style="margin-bottom: 100px;">

        <div class="ratio ratio-16x9">
          <div id="training-recruiters">

            <div class="card h-200">
              <div class="card-header">
                <h4 class="card-title mb-0">Karla Luna - Módulo 1</h4>
              </div>
              <div class="card-body">
                <iframe src="https://player.vimeo.com/video/948883258?h=fae10e5ca1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style="top:0;left:0;width:100%;height:100%; border-radius: 10px;"
                        title="Karla Luna - DMB PRESENTATION">
                </iframe>
              </div>
              <div class="card-footer">
                <p class="card-text">
                  <small class="text-muted">Last updated 30 days ago</small>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-xl-4"  style="margin-bottom: 100px;">
        <!-- Ratio Video 16:9 -->
        <div class="ratio ratio-16x9">
          <div id="training-recruiters">

            <div class="card h-200">
              <div class="card-header">
                <h4 class="card-title mb-0">Karla Luna - Módulo 2</h4>
              </div>
              <div class="card-body">
                <iframe src="https://player.vimeo.com/video/948883407?h=30914c736c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style="top:0;left:0;width:100%;height:100%; border-radius: 10px;"
                        title="Karla Luna - Preguntas Frecuentes">
                </iframe>
              </div>
              <div class="card-footer">
                <p class="card-text">
                  <small class="text-muted">Last updated 30 days ago</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4" style="margin-bottom: 100px;">
        <div class="ratio ratio-16x9">
          <div id="training-recruiters">

            <div class="card h-200">
              <div class="card-header">
                <h4 class="card-title mb-0">Karla Luna - Modulo 3</h4>
              </div>
              <div class="card-body">
                <iframe src="https://player.vimeo.com/video/948883489?h=6343a23ab3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style="top:0;left:0;width:100%;height:100%; border-radius: 10px;"
                        title="Karla Luna - Modulo 3">
                </iframe>
              </div>
              <div class="card-footer">
                <p class="card-text">
                  <small class="text-muted">Last updated 30 days ago</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4" style="margin-bottom: 100px;">


        <div class="ratio ratio-16x9">
          <div id="training-recruiters">

            <div class="card h-200">
              <div class="card-header">
                <h4 class="card-title mb-0">Karla Luna - Módulo 4</h4>
              </div>
              <div class="card-body">
                <iframe src="https://player.vimeo.com/video/948883517?h=b55a97aa7d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style="top:0;left:0;width:100%;height:100%; border-radius: 10px;"
                        title="Karla Luna - Módulo 4">
                </iframe>
              </div>
              <div class="card-footer">
                <p class="card-text">
                  <small class="text-muted">Last updated 30 days ago</small>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </Layout>
</template>
<style>

</style>
